<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="すべてのマニフェスト" />
    <AppSideMenu />
    <main class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />

      <!-- 検索フォーム ============================================================ -->
      <SearchForm v-if="isGenerator" @search="getManifestSearchApi" />
      <SearchFormCollector v-else-if="isCollector" @search="getManifestSearchApi" />
      <SearchFormDisposal v-else-if="isDisposer" @search="getManifestSearchApi" />

      <!-- 検索結果 ============================================================ -->
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <!-- 排出事業者用 -->
        <ManifestTable
          v-if="isGenerator"
          :paginationParams="paginationParams"
          :manifestList.sync="manifestList"
          :isAllChecked.sync="isAllChecked"
          :isBulkEdit="isBulkEdit"
          :sortParams.sync="sortParams"
          :checkCount="checkCount"
          :checkedUnderReserveCount="checkedUnderReserveCount"
          :sealedManifestCount="isSealedManifestCount"
          :isCallApiProcess="isCallApiProcess"
          :wasteUnitOpt="wasteUnitOpt"
          :validate="validate"
          :bundleIssueErrorList.sync="bundleIssueErrorList"
          @clickSortChange="sortChangeHandler"
          @clickAllCheck="changeAllCheckHandler"
          @clickEachCb="changeManifestCbHandler"
          @clickIssue="issueHandler"
          @clickReserve="reserveHandler"
          @clickPrint="showChecksheetDownloadModal"
          @clickCSVDownload_backup="csvDownloadHandler"
          @clickCSVDownload="$refs.csvdownloadmodal.updateCSVModal(true)"
          @clickCancel="cancelHandler"
          @clickCancelBulkEdit="cancelBulkEdit"
          @clickSubmitBulkEdit="submitBulkEdit"
          @onUploadCsv="onUploadCsv"
          @pushEditValidate="pushEditValidate"
          @removeEditValidate="removeEditValidate"
        />

        <!-- 運搬事業者用 -->
        <ManifestTableCollector
            v-else-if="isCollector"
            :paginationParams="paginationParams"
            :manifestList.sync="manifestList"
            :isAllChecked.sync="isAllChecked"
            :sortParams.sync="sortParams"
            :checkCount="checkCount"
            :isCallApiProcess="isCallApiProcess"
            :bundleIssueErrorList="bundleIssueErrorList"
            @clickSortChange="sortChangeHandler"
            @clickAllCheck="changeAllCheckHandler"
            @clickEachCb="changeManifestCbHandler"
            @clickPrint="showChecksheetDownloadModal"
            @clickCSVDownloadForCollector_backup="clickCSVSearchByFormatHandlerForCollector"
            @clickCSVDownloadForCollector="$refs.csvdownloadmodal.updateCSVModal(true)"
            @onUploadCsv="onUploadCsv"
        />

        <!-- 処分事業者用 排出事業者用流用-->
        <ManifestTableDisposer
          v-else-if="isDisposer"
          :paginationParams="paginationParams"
          :manifestList.sync="manifestList"
          :isAllChecked.sync="isAllChecked"
          :isBulkEdit="isBulkEdit"
          :sortParams.sync="sortParams"
          :checkCount="checkCount"
          :checkedUnderReserveCount="checkedUnderReserveCount"
          :sealedManifestCount="isSealedManifestCount"
          :isCallApiProcess="isCallApiProcess"
          :wasteUnitOpt="wasteUnitOpt"
          :validate="validate"
          :bundleIssueErrorList.sync="bundleIssueErrorList"
          @clickSortChange="sortChangeHandler"
          @clickAllCheck="changeAllCheckHandler"
          @clickEachCb="changeManifestCbHandler"
          @clickIssue="issueHandler"
          @clickReserve="reserveHandler"
          @clickPrint="showChecksheetDownloadModal"
          @clickCSVDownloadForDisposer_backup="clickCSVSearchByFormatHandlerForDisposer"
          @clickCSVDownloadForDisposer="$refs.csvdownloadmodal.updateCSVModal(true)"
          @clickCancel="cancelHandler"
          @clickCancelBulkEdit="cancelBulkEdit"
          @clickSubmitBulkEdit="submitBulkEdit"
          @onUploadCsv="onUploadCsv"
          @pushEditValidate="pushEditValidate"
          @removeEditValidate="removeEditValidate"
        />

        <Pagination v-if="manifestList.length"
          :params="paginationParams"
          :page.sync="paginationParams.page"
          @callback="getManifestSearchApi(searchedParam, true)"
        />
      </div>

      <CSVDownloadModal
        ref="csvdownloadmodal"
        :paginationParams="paginationParams"
        @downloadCSVHandler="downloadCSVHandler"
      />
    </main>

    <!-- 登録モーダル -->
    <div class="c-modal lg" v-show="isChecksheetDownloadModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">

        <div class="c-modal__head">
          <h5 class="c-lead-s">受渡確認票印刷ダウンロード</h5>
          <span class="c-modal__head__close" @click="closeModalHandler"></span>
        </div>
        <div class="c-modal__body">
          <p class="u-txt-center">受け渡し確認票をダウンロードする形式を選択してください</p>
          <p class="u-txt-center" v-if="checksheetDownloadMessage">{{ checksheetDownloadMessage }}</p>

          <div
          v-for="cdt in checksheetDownloadTypes"
          :key="cdt.id"
          class="c-modal__foot"
          >
            <div>
              <div class="c-btn primary download-sm" :class="{ disabled: isDisableChecksheetDownload }" @click="checksheetDownloadHandler(cdt.value)">{{ cdt.label }}</div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div class="c-btn secondary small" @click="closeModalHandler">閉じる</div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import SearchForm from "./components/SearchForm.vue";
import ManifestTable from "./components/ManifestTable.vue";
import ManifestTableCollector from "./components/ManifestTableCollector.vue";
import ManifestTableDisposer from "./components/ManifestTableDisposer.vue";
import Pagination from "@/components/parts/Pagination";
import search from "./mixins/search";
import { API_URL, DOMAIN } from "@/const";
import moment from "moment";
import { saveAs } from "file-saver";
import ToastMessage from "@/components/parts/ToastMessage";
import {DATE_LIMIT_STATUS} from "@/const/dateLimit";
import editValidate from "./mixins/editValidate";
import SearchFormCollector from "./components/SearchFormCollector";
import callApi from "./mixins/callApi";
import CSVDownloadModal from "../components/CSVDownloadModal";
import SearchFormDisposal from "./components/SearchFormDisposal";

export default {
  name: "manifest-all",
  components: {
    SearchFormCollector,
    SearchForm,
    ManifestTable,
    ManifestTableCollector,
    ManifestTableDisposer,
    Pagination,
    ToastMessage,
    CSVDownloadModal,
    SearchFormDisposal,
  },
  mixins: [search, editValidate, callApi],
  data() {
    return {
      message: "",
      isAllChecked: false,
      isAdmin: this.$store.getters["userInfo/isAdmin"],
      userId: this.$store.getters["userInfo/get"].id,
      isGenerator: this.$store.getters["userInfo/isGenerator"],
      isCollector: this.$store.getters["userInfo/isCollector"],
      isDisposer: this.$store.getters["userInfo/isDisposer"],
      checkCount: 0,
      checkedUnderReserveCount: 0,
      isSealedManifestCount: 0,
      manifestList: [],
      // 検索内容別で持つ。ページ移動でもAPI叩くため
      searchedParam: {},
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 20,
        total: null,
      },
      sortParams: {
        // column: "manifest_no",
        column: "collection_date",
        type: "desc",
      },
      errMsgs: [],
      toastMsg: "",
      isBulkEdit: false,
      isChecksheetDownloadModalShow: false,
      checksheetDownloadTypes: [
        {
          label: "PDFダウンロード(複数一括)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.BUNDLE_PDF,
        },
        {
          label: "PDFダウンロード(単票)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_ONE_SHEET,
        },
        {
          label: "PDFダウンロード(連票)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_THREE_SHEET,
        },
        {
          label: "EXCELダウンロード",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.EXCEL_ZIP,
        },
      ],
      isDisableChecksheetDownload: false,
      checksheetDownloadMessage: "",
      isCallApiProcess: false, // 一括交付、削除ボタンの処理中フラグ
      wasteUnitOpt: [],
      bundleIssueErrorList: [],
      disposalStoreList:[],
      disposalCompanyId:"",
      disposalCompanyName:"",
    };
  },
  methods: {
    closeModalHandler() {
      this.isChecksheetDownloadModalShow = false;
    },

    // マニフェスト全件チェックボックス
    changeAllCheckHandler(isAllChecked) {
      this.isAllChecked = isAllChecked;
      if (this.isAllChecked) {
        this.manifestList = this.manifestList.map((manifest) => {
          manifest.isChecked = true;
          return manifest;
        });
      } else {
        this.manifestList = this.manifestList.map((manifest) => {
          manifest.isChecked = false;
          return manifest;
        });
      }
    },

    // 期限ステータスチェック
    checkLimitStatus(date, issueAlertType) {
      if (!date) return DATE_LIMIT_STATUS.INFO;
      if (issueAlertType === 2) {
        return DATE_LIMIT_STATUS.DANGER;
      } else if (issueAlertType === 1) {
        return DATE_LIMIT_STATUS.WARNING;
      } else return DATE_LIMIT_STATUS.NOT_YET;
    },

    // 各マニフェストのチェックボックス
    changeManifestCbHandler(selectedIndex, manifest) {
      manifest.isChecked = !manifest.isChecked;
      this.$set(this.manifestList, selectedIndex, manifest);
      this.isAllChecked = this.manifestList.length === this.manifestList.filter(m => m.isChecked).length;
    },

    // 引き渡し日ソート変更
    sortChangeHandler(newSortType) {
      this.sortParams.type = newSortType;
      this.$store.commit("searchParamManifestsAll/setManifestAllPage", 1);
      this.getManifestSearchApi(this.searchedParam, false);
    },

    removeEditValidate(manifestId) {
      const deleteIndex = this.validate.manifestList.findIndex(v => v.manifestId === manifestId);
      this.validate.manifestList.splice(deleteIndex, 1);
    },

    pushEditValidate(manifestId) {
      const editValidate = JSON.parse(JSON.stringify(this.dummyValidate));
      editValidate.manifestId = manifestId;

      this.validate.manifestList.push(editValidate)
    },

    getCheckedManifestIdList() {
      const checkedManifests = this.manifestList.filter(
        (manifest) => manifest.isChecked
      );
      const manifestIdList = checkedManifests.map((manifest) => {
        return { manifestId: manifest.manifestId };
      });
      return manifestIdList;
    },
    /* 期限切れのマニフェストのチェック */
    expiredManifestValidate() {
      // 選択されたデータ且つ編集可能なデータを抽出
      this.manifestList.filter(
        (manifest) => {
          // マニフェストステータス 11,12が対象
          if(manifest.isChecked && manifest.manifestStatusType.id < 19 && manifest.manifestStatusType.id !== 10){
              return true;
          }
        }
      );
      const expiredManifests = this.manifestList.filter(m => m.isChecked).filter(m => m.expirationDate && moment().isAfter(m.expirationDate) && m.manifestStatusType.id === DOMAIN.MANIFEST_STATUS.RESERVED)
      expiredManifests.map( m => {
         this.bundleIssueErrorList.push(m.manifestId)
      })
      return expiredManifests.length === 0;
    },
    /*
     * 対象データが交付可能なデータか確認する
     * 一覧データで確認できない箇所はAPI側でキャッチする
     */
    issueDataValidate() {
      // 配列情報を初期化
      this.bundleIssueErrorList = [];
      // 選択されたデータ且つ編集可能なデータを抽出
      const checkedManifests = this.manifestList.filter(
        (manifest) => {
          // マニフェストステータス 11,12が対象
          if(manifest.isChecked && manifest.manifestStatusType.id < 19 && manifest.manifestStatusType.id !== 10){
              return true;
          }
        }
      );
      // 選択された各種交付対象データの内容が正しいかチェックする
      checkedManifests.forEach(cm => {
        let isValidData = true;
        // 引き渡し日チェック
        if(!cm.collectionDate) {
          isValidData = false;
        }
        // 引き渡し担当者チェック
        if(!cm.generateMember || !cm.generateMember.name) {
          isValidData = false;
        }
        // 登録担当者チェック
        if(!cm.registMember || !cm.registMember.name) {
          isValidData = false;
        }
        // ルート選択チェック
        if(!cm.routeId) {
          isValidData = false;
        }
        // 廃棄物種類チェック★
        if (cm.jwnetItemType) {
          if (!cm.jwnetItemType.name) {
            isValidData = false;
          }
        } else {
          isValidData = false;
        }
        // 廃棄物名称チェック★
        if (cm.wasteName) {
          if(!cm.wasteName.name) {
            isValidData = false;
          }
        } else {
          isValidData = false;
        }
        // 詳細名称チェック
        if (cm.detailWasteName) {
          //30文字以内チェック
          if (cm.detailWasteName.length >= 30) {
            isValidData = false;
          }
        }
        // 廃棄物数量チェック
        if(!cm.wasteQuantity) {
          isValidData = false;
        }
        // 廃棄物数量単位チェック
        if(cm.wasteUnitType) {
          if (!cm.wasteUnitType.name) {
            isValidData = false;
          }
        } else {
          isValidData = false;
        }
        // 荷姿チェック
        if(cm.shapeType) {
          if (!cm.shapeType.name) {
            isValidData = false;
          }
        } else {
          isValidData = false;
        }

        if(!isValidData) {
          this.bundleIssueErrorList.push(cm.manifestId);
        }
      });
      // チェック項目で引っかかったマニフェスト情報がなかった場合、交付APIを呼ぶ
      return this.bundleIssueErrorList.length === 0;
    },
    // チェックしたのは未予約のみチェック
    includeJustNotReservedManifestValidate() {
      return this.manifestList.filter(m => m.isChecked && m.manifestStatusType.id !== DOMAIN.MANIFEST_STATUS.NOT_RESERVE && m.manifestStatusType.id !== DOMAIN.MANIFEST_STATUS.RESERVE_PENDING).length === 0
    },
    // 交付押下
    issueHandler() {
      this.errMsgs = [];
      this.toastMsg = "";
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      if(!this.issueDataValidate() && !this.expiredManifestValidate()){
        this.errMsgs.push("交付できないマニフェストが含まれます。マニフェストの設定情報をご確認ください。");
        return;
      } else if (!this.issueDataValidate()) {
        this.errMsgs.push("必要な情報が不足しています。詳細画面で内容を修正してください。");
        return;
      } else if (!this.expiredManifestValidate()) {
        this.errMsgs.push("マニフェストの有効期限が切れています。");
        return;
      }
      this.isCallApiProcess = true;
      axios.put(API_URL.MANIFEST.BUNDLE_ISSUE
            , { manifestList: this.getCheckedManifestIdList() }
        )
        .then(() => {
          // リロード
          this.getManifestSearchApi(this.$store.getters["searchParamManifestsAll/get"], true).then(() => {
            this.isCallApiProcess = false;
          });
          // 成功時のメッセージの設定
          this.toastMsg = `${this.getCheckedManifestIdList().length}件の交付を行いました。`;
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.isCallApiProcess = false;
        });
    },

    /** 予約押下 */
    reserveHandler() {
      this.errMsgs = [];
      this.toastMsg = "";
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];
      if(!this.includeJustNotReservedManifestValidate()) {
        this.errMsgs.push("予約できないマニフェストが含まれます。マニフェストの設定情報をご確認ください。");
        return;    
      }
      this.isCallApiProcess = true;
      axios.put(API_URL.MANIFEST.RESERVE
            , { manifestList: this.getCheckedManifestIdList() }
        )
        .then(() => {
          // リロード
          this.getManifestSearchApi(this.$store.getters["searchParamManifestsAll/get"], true).then(() => {
            this.isCallApiProcess = false;
          });
          // 成功時のメッセージの設定
          this.toastMsg = `${this.getCheckedManifestIdList().length}件の予約を行いました。`;
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.isCallApiProcess = false;
        });
    },

    // 受渡確認票をダウンロード
    checksheetDownloadHandler(downloadTypeId) {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      this.isDisableChecksheetDownload = true;
      this.checksheetDownloadMessage = `${this.checksheetDownloadTypes.find(t => t.value === downloadTypeId).label}を行なっています。少々お待ちください。`;
      axios
        .post(API_URL.MANIFEST.DELIVERY_CHECKSHEET
            , { downLoadTypeId: downloadTypeId, manifestIdList: this.getCheckedManifestIdList() }
            , { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          if (downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_ONE_SHEET) {
            saveAs(blob, `受渡確認表(単票)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else if(downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_THREE_SHEET) {
            saveAs(blob, `受渡確認表(連票)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else if(downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.BUNDLE_PDF) {
            saveAs(blob, `PDFダウンロード(複数一括)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else {
            saveAs(blob, `受渡確認表_${moment().format("YYYYMMDDHHmmss")}.zip`);
          }
        })
        .then(() => {
          // ダウンロードボタンは活生にする
          this.isDisableChecksheetDownload = false;
          this.checksheetDownloadMessage = "";
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
      
    },

    // 受渡確認票印刷押下
    showChecksheetDownloadModal() {
      this.isChecksheetDownloadModalShow = true;
    },


    // cs202201：CSVフォーマット関連
    /**
     * CSVフォーマット選択モーダル内ダウンロードボタン押下時
     */
    downloadCSVHandler(selectID) {
      if (this.isGenerator) {
        // 2022.03.09 全項目とフォーマット指定のAPIを一本化
        this.clickCSVSearchByFormatHandler(selectID);
      } else if (this.isCollector) {
        this.clickCSVSearchByFormatHandlerForCollector(selectID);
      } else if (this.isDisposer) {
        this.clickCSVSearchByFormatHandlerForDisposer(selectID);
      }
    },

    // 運搬用CSVダウンロード用の検索条件パラメーター
    getCSVDownloadSearchParamForCollector() {
      let generateStoreIdList = [];
      if (this.searchedParam.generateStoreIdList) {
        this.searchedParam.generateStoreIdList.forEach (
            generateStoreId => {
              if (generateStoreId) {
                generateStoreIdList.push(generateStoreId);
              }
            }
        )
      }

      return {
        searchConditionId: String(DOMAIN.MANIFEST_SEARCH_CONDITION.ALL),
        wasteName: this.searchedParam.wasteName === "" ? null : this.searchedParam.wasteName,
        generateCompanyId: this.searchedParam.generateCompanyId === "" ? null : this.searchedParam.generateCompanyId,
        manifestNo: this.searchedParam.manifestNo === "" ? null : this.searchedParam.manifestNo,
        collectionDateFrom: this.searchedParam.collectionDateFrom === "" ? null : this.searchedParam.collectionDateFrom,
        collectionDateTo: this.searchedParam.collectionDateTo === "" ? null : this.searchedParam.collectionDateTo,
        cbaItemId: this.searchedParam.cbaItemId === "" ? null : this.searchedParam.cbaItemId,
        jwnetItemTypeId: this.searchedParam.jwnetItemTypeId === "" ? null : this.searchedParam.jwnetItemTypeId,
        routeId: this.searchedParam.routeId === "" ? null : this.searchedParam.routeId,
        routeName: this.searchedParam.routeName === "" ? null : this.searchedParam.routeName,
        collectManifestSearchTypeIdList: this.searchedParam.status,
        generateStoreId: generateStoreIdList,
        disposalCompanyId: this.searchedParam.disposalCompanyId === "" ? null : this.searchedParam.disposalCompanyId,
        disposalSiteId: this.searchedParam.disposalSiteId === "" ? null : this.searchedParam.disposalSiteId,
        wasteTypeList: this.searchedParam.wasteType,
        jwnetItemTypeFieldList: this.searchedParam.jwnetItemTypeField,
        contactNo: this.searchedParam.contactNo === "" ? null : this.searchedParam.contactNo,
        remarks: this.searchedParam.remarks === "" ? null : this.searchedParam.remarks,
        isActive: this.searchedParam.isActive === false ? null : this.searchedParam.isActive,
        issueDateFrom: this.searchedParam.issueDateFrom === "" ? null : this.searchedParam.issueDateFrom,
        issueDateTo: this.searchedParam.issueDateTo === "" ? null : this.searchedParam.issueDateTo,
        collectionReportDateFrom: this.searchedParam.collectionReportDateFrom === "" ? null : this.searchedParam.collectionReportDateFrom,
        collectionReportDateTo: this.searchedParam.collectionReportDateTo === "" ? null : this.searchedParam.collectionReportDateTo,
        sort: this.sortParams.column + ":" + this.sortParams.type
      };
    },

    // CSVダウンロード用の検索条件パラメーター
    getCSVDownloadSearchParam() {
      let generateStoreIdList = [];
      if (this.searchedParam.generateStoreIdList) {
        this.searchedParam.generateStoreIdList.forEach (
            generateStoreId => {
              if (generateStoreId) {
                generateStoreIdList.push(generateStoreId);
              }
            }
        )
      }

      return {
        searchConditionId: String(DOMAIN.MANIFEST_SEARCH_CONDITION.ALL),
        wasteName: this.searchedParam.wasteName === "" ? null : this.searchedParam.wasteName,
        collectCompanyId: this.searchedParam.collectCompanyId === "" ? null : this.searchedParam.collectCompanyId,
        generateCompanyId: this.searchedParam.generateCompanyId === "" ? null : this.searchedParam.generateCompanyId,
        collectionDateFrom: this.searchedParam.collectionDateFrom === "" ? null : this.searchedParam.collectionDateFrom,
        collectionDateTo: this.searchedParam.collectionDateTo === "" ? null : this.searchedParam.collectionDateTo,
        generateStoreId: generateStoreIdList,
        manifestSearchType: this.searchedParam.status,
        disposalCompanyId: this.searchedParam.disposalCompanyId === "" ? null : this.searchedParam.disposalCompanyId,
        disposalSiteId: this.searchedParam.disposalSiteId === "" ? null : this.searchedParam.disposalSiteId,
        manifestNo: this.searchedParam.manifestNo === "" ? null : this.searchedParam.manifestNo,
        contactNo: this.searchedParam.contactNo === "" ? null : this.searchedParam.contactNo,
        issueDateFrom: this.searchedParam.issueDateFrom === "" ? null : this.searchedParam.issueDateFrom,
        issueDateTo: this.searchedParam.issueDateTo === "" ? null : this.searchedParam.issueDateTo,
        jwnetItemTypeId: this.searchedParam.jwnetItemTypeId === "" ? null : this.searchedParam.jwnetItemTypeId,
        cbaItemId: this.searchedParam.cbaItemId === "" ? null : this.searchedParam.cbaItemId,
        routeName: this.searchedParam.routeName === "" ? null : this.searchedParam.routeName,
        routeId: this.searchedParam.routeId === "" ? null : this.searchedParam.routeId,
        wasteTypeList: this.searchedParam.wasteType,
        manifestTypeList: this.searchedParam.manifestType,
        jwnetItemTypeFieldList: this.searchedParam.jwnetItemTypeField,
        collectReportSearchType: this.searchedParam.collectReportSearchType,
        disposalReportSearchType: this.searchedParam.disposalReportSearchType,
        finalDisposalReportSearchType: this.searchedParam.finalDisposalReportSearchType,
        termDateTypeList: this.searchedParam.termDateType,
        reportDateFrom: this.searchedParam.reportDateFrom === "" ? null : this.searchedParam.reportDateFrom,
        reportDateTo: this.searchedParam.reportDateTo === "" ? null : this.searchedParam.reportDateTo,
        registDateFrom: this.searchedParam.registDateFrom === "" ? null : this.searchedParam.registDateFrom,
        registDateTo: this.searchedParam.registDateTo === "" ? null : this.searchedParam.registDateTo,
        remarks: this.searchedParam.remarks === "" ? null : this.searchedParam.remarks,
        manifestLabel: this.searchedParam.manifestLabel === "" ? null : this.searchedParam.manifestLabel,
        isMine: this.searchedParam.isMine,
        isActive: this.searchedParam.isActive,
        sort: this.sortParams.column + ":" + this.sortParams.type,
      };
    },

    getCSVDownloadSearchParamForDisposer() {
      let generateStoreIdList = [];
      if (this.searchedParam.generateStoreIdList) {
        this.searchedParam.generateStoreIdList.forEach (
            generateStoreId => {
              if (generateStoreId) {
                generateStoreIdList.push(generateStoreId);
              }
            }
        )
      }

      return {
        wasteName: this.searchedParam.wasteName === "" ? null : this.searchedParam.wasteName,
        collectCompanyId: this.searchedParam.collectCompanyId === "" ? null : this.searchedParam.collectCompanyId,
        collectionDateFrom: this.searchedParam.collectionDateFrom === "" ? null : this.searchedParam.collectionDateFrom,
        collectionDateTo: this.searchedParam.collectionDateTo === "" ? null : this.searchedParam.collectionDateTo,
        generateCompanyId: this.searchedParam.generateCompanyId === "" ? null : this.searchedParam.generateCompanyId,
        generateStoreId: generateStoreIdList,
        manifestSearchType: this.searchedParam.status,
        disposalCompanyId: this.searchedParam.disposalCompanyId === "" ? null : this.searchedParam.disposalCompanyId,
        disposalSiteId: this.searchedParam.disposalSiteId === "" ? null : this.searchedParam.disposalSiteId,
        manifestNo: this.searchedParam.manifestNo === "" ? null : this.searchedParam.manifestNo,
        contactNo: this.searchedParam.contactNo === "" ? null : this.searchedParam.contactNo,
        issueDateFrom: this.searchedParam.issueDateFrom === "" ? null : this.searchedParam.issueDateFrom,
        issueDateTo: this.searchedParam.issueDateTo === "" ? null : this.searchedParam.issueDateTo,
        jwnetItemTypeId: this.searchedParam.jwnetItemTypeId === "" ? null : this.searchedParam.jwnetItemTypeId,
        cbaItemId: this.searchedParam.cbaItemId === "" ? null : this.searchedParam.cbaItemId,
        routeName: this.searchedParam.routeName === "" ? null : this.searchedParam.routeName,
        routeId: this.searchedParam.routeId === "" ? null : this.searchedParam.routeId,
        wasteTypeList: this.searchedParam.wasteType,
        manifestTypeList: this.searchedParam.manifestType,
        jwnetItemTypeFieldList: this.searchedParam.jwnetItemTypeField,
        collectReportSearchType: this.searchedParam.collectReportSearchType,
        disposalReportSearchType: this.searchedParam.disposalReportSearchType,
        finalDisposalReportSearchType: this.searchedParam.finalDisposalReportSearchType,
        termDateTypeList: this.searchedParam.termDateType,
        reportDateFrom: this.searchedParam.reportDateFrom === "" ? null : this.searchedParam.reportDateFrom,
        reportDateTo: this.searchedParam.reportDateTo === "" ? null : this.searchedParam.reportDateTo,
        registDateFrom: this.searchedParam.registDateFrom === "" ? null : this.searchedParam.registDateFrom,
        registDateTo: this.searchedParam.registDateTo === "" ? null : this.searchedParam.registDateTo,
        remarks: this.searchedParam.remarks === "" ? null : this.searchedParam.remarks,
        manifestLabel: this.searchedParam.manifestLabel === "" ? null : this.searchedParam.manifestLabel,
        isMine: this.searchedParam.isMine === false ? null : this.searchedParam.isMine,
        isActive: this.searchedParam.isActive === false ? null : this.searchedParam.isActive,
        isProxyRegist: this.searchedParam.isProxyRegist === false ? null : this.searchedParam.isProxyRegist,
        sort: this.sortParams.column + ":" + this.sortParams.type,
      };
    },

    // CSVダウンロード押下
    csvDownloadHandler() {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      axios
        .post(API_URL.MANIFEST.CSV_DOWNLOAD
            , this.getCSVDownloadSearchParam()
            , { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          saveAs(blob, `マニフェスト検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
          this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
          this.$refs.csvdownloadmodal.updateCSVModal(false);
        });
    },

    clickCSVSearchByFormatHandlerForCollector(csvformatId) {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      axios
          .post(API_URL.MANIFEST.COLLECT_CSV_DOWNLOAD_BY_FORMAT
              , {
                manifestSearchFileDownloadInfoForCollector: this.getCSVDownloadSearchParamForCollector(),
                csvformatId: csvformatId
              }
              , { responseType: "blob" }
          )
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type
            });
            saveAs(blob, `マニフェスト検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
            this.$refs.csvdownloadmodal.updateCSVModal(false);
          });
    },

    // フォーマット指定したCSVダウンロード押下
    clickCSVSearchByFormatHandler(csvformatId) {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      axios
          .post(API_URL.MANIFEST.CSV_DOWNLOAD_BY_FORMAT
              , {
                manifestSearchFileDownloadInfo: this.getCSVDownloadSearchParam(),
                csvformatId: csvformatId
              }
              , { responseType: "blob" }
          )
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type
            });
            saveAs(blob, `マニフェスト検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
            this.$refs.csvdownloadmodal.updateCSVModal(false);
          });
    },

    clickCSVSearchByFormatHandlerForDisposer(csvformatId) {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      axios
          .post(API_URL.MANIFEST.DISPOSAL_CSV_DOWNLOAD_BY_FORMAT
              , {
                manifestSearchFileDownloadInfoForDisposer: this.getCSVDownloadSearchParamForDisposer(),
                csvformatId: csvformatId
              }
              , { responseType: "blob" }
          )
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type
            });
            saveAs(blob, `マニフェスト検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
            this.$refs.csvdownloadmodal.updateCSVModal(false);
          });
    },

    // 取消押下 manifestStatusId: 90は「削除」
    cancelHandler() {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      const manifestIdList = this.getCheckedManifestIdList();
      this.isCallApiProcess = true;

      axios
        .patch(API_URL.MANIFEST.STATUS_UPDATE, {
          manifestIdList: manifestIdList,
          manifestStatusId: DOMAIN.MANIFEST_STATUS.DELETE,
        })
        .then(() => {
          // リロード
          this.getManifestSearchApi(this.$store.getters["searchParamManifestsAll/get"], true).then(() => {
            this.isCallApiProcess = false;
          });
        })
        .catch((err) => {
          this.isCallApiProcess = false;
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
    },


    // 一括編集のキャンセル
    cancelBulkEdit() {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      this.manifestList = this.manifestList.map(manifest => {
        manifest.isEdit = false;
        return manifest;
      });
    },

    // 一括編集の確定
    submitBulkEdit() {
      this.isCallApiProcess = true
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      if (!this.runValidate()) {
        this.isCallApiProcess = false
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      const list = this.manifestList.filter(m => m.isEdit).map(m => {
        return {
          manifestId: m.manifestId,
          collectionDate: m.editCollectionDate,
          wasteQuantity: m.editWasteQuantity,
          wasteQuantityUnitTypeId: m.editWasteQuantityUnitTypeId,
        };
      });
      axios
          .put(API_URL.MANIFEST.BULK_EDIT, { manifestReserveEditList: list })
          .then(() => {
            // リロード
            this.getManifestSearchApi(this.$store.getters["searchParamManifestsAll/get"], true).then(() => {
              this.isCallApiProcess = false
            })
            // 成功時のメッセージの設定
            this.toastMsg = `${list.length}件の編集を行いました。`;
            window.scrollTo({ top: 0, behavior: "smooth" });
          })
          .catch((err) => {
            this.isCallApiProcess = false
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
    },

    onUploadCsv() {
      this.toastMsg = "正常にアップロードしました。";
      // 再検索を行う
      this.getManifestSearchApi(this.$store.getters["searchParamManifestsAll/get"], true);
    },
    addRequestToLocation(requestObj) {
      const param = new URLSearchParams(requestObj)
      history.replaceState(
      {},
      null,
      this.$route.path + '?' + param
    )
    }
  },
  beforeRouteEnter(to, from, next) {
    next( (vm) => {
      if(from.path.includes("/manifest")) {
        let page = vm.$store.getters["searchParamManifestsAll/getManifestAllPage"]
        vm.paginationParams.page = page
      } else {
        // リセットする
        vm.$store.commit("searchParamManifestsAll/setManifestAllPage", 1);
      }
    })
  },
  watch: {
    manifestList: {
      handler: function () {
        this.manifestList.forEach(manifest => {
          manifest.collectionDateLimit = this.checkLimitStatus(manifest.collectionDate, manifest.issueAlertType);
        });

        this.checkCount = this.manifestList.filter(m => m.isChecked).length;
        this.isBulkEdit = !!this.manifestList.filter(m => m.isEdit).length;
        this.checkedUnderReserveCount = this.manifestList.filter(m => m.isChecked).filter(m => (m.manifestStatusType.id === DOMAIN.MANIFEST_STATUS.UNDER_RESERVE
                                                                                                || m.manifestStatusType.id === DOMAIN.MANIFEST_STATUS.UNDER_DELETE
                                                                                                || m.manifestStatusType.id === DOMAIN.MANIFEST_STATUS.DELETE)).length;
        //確定状況チェック
        this.isSealedManifestCount = this.manifestList.filter(m => m.isChecked).filter(m => m.isEditable === false).length                                                              
      },
      deep: true,
    },
  },

  beforeCreate() {
    // クエリパラメータのチェック
    const isActive = this.$route.query.isActive ? this.$route.query.isActive === 'true' : "";
    if (this.$route.query.status) {
      this.$store.commit("searchParamManifestsAll/set", {
        wasteName: "",
        collectCompanyId: "",
        generateCompanyId: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        status: this.$route.query.status.split(",").map(s => Number(s)),
        manifestType: [],
        generateStoreIdList: [null],
        disposalCompanyId: "",
        disposalSiteId: "",
        manifestNo: "",
        contactNo: "",
        jwnetItemTypeId: "",
        cbaItemId: "",
        routeName: "",
        routeId: "",
        wasteType: [],
        jwnetItemTypeField: [],
        termDateType: [],
        reportDateFrom: "",
        reportDateTo: "",
        registDateFrom: "",
        registDateTo: "",
        remarks: "",
        manifestLabel: "",
        isMine: "",
        isActive: isActive,
        isProxyRegist: "",
      });
    }
  },

  mounted() {
    this.getManifestSearchApi(this.$store.getters["searchParamManifestsAll/get"], false);
    this.getWasteUnitApi();
  },
};
</script>
