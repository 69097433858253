<template>
  <div>
    <div class="c-searchMenu__wrap">
      <div v-if="isDisplaySearch" class="c-searchMenu">
        <div class="l-flex">
          <div v-if="shorten().length === 0">
            <div class="title icon">条件なし</div>
          </div>
          <template v-else v-for="(s, index) in shorten()">
            <div class="title" :class="[{ icon: index === 0 }]" :key="'shorten-' + index">
              {{ s.key }}<div class="data"> : {{ s.value }}</div>
            </div>
          </template>
        </div>
        <div class="l-flex">
          <div class="c-btn secondary sm" @click="scrollTop">検索条件を変更</div>
        </div>
      </div>
    </div>
    <div class="l-full-white l-area">
      <div class="l-box l-flex">
        <h3 class="c-lead-s-black icon">検索</h3>
        <div class="c-tabs l-area-l">
          <div
            class="c-tabs__each"
            :class="{ active: !isSearchDetail }"
            @click="isSearchDetail = false; initSearch(false);"
          >簡易</div>
          <div
            class="c-tabs__each"
            :class="{ active: isSearchDetail }"
            @click="isSearchDetail = true; initSearch(false);"
          >詳細</div>
        </div>
      </div>
      <div class="c-searchMenu__wrap__formWrap">
        <div class="c-inputWrap">
          <div class="c-inputWrap__items__cols search-wrap">
            <div>
              <span class="text-label">廃棄物名称</span>
              <InputText
                :value.sync="searchParam.wasteName"
                inputType="text"
                placeholder="廃棄物名称を入力"
                :validation="validateMaxLen"
                :validateArg="100"
              >
              </InputText>
            </div>
            <div>
                <span class="text-label">排出事業者</span>
                  <v-autocomplete ref="generate_autocomplete" @change="s => {onChange(s, 'generate_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectDisposalGenerateList" :min-len="0" :wait="300" @update-items='updateDisposalGenerateList' v-model="selectedDisposalGenerate" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                  </v-autocomplete>
              </div>
            <div>
              <span class="text-label">マニフェスト番号</span>
              <InputText
                :value.sync="searchParam.manifestNo"
                inputType="text"
                placeholder="マニフェスト番号を入力"
                :validation="validateNumber"
              >
              </InputText>
            </div>
            <div>
              <span class="text-label">引き渡し日</span>
              <div class="c-inputWrap__items__cols">
                <InputDate
                  :value.sync="searchParam.collectionDateFrom"
                  placeholder="0000/00/00"
                />
                <span>〜</span>
                <InputDate
                  :value.sync="searchParam.collectionDateTo"
                  placeholder="0000/00/00"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__items__cols search-wrap">
            <div>
              <span class="text-label">CBA品目</span>
              <Selectbox
                class="mid"
                :value.sync="searchParam.cbaItemId"
                :validation="validateJustBlank"
              >
                <option value="">すべて</option>
                <option
                  v-for="(opt, index) in cbaItems"
                  :key="`cba-opt-${index}`"
                  :value="opt.id"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
            <div>
              <span class="text-label">廃棄物種類</span>
              <Selectbox
                class="mid"
                :value.sync="searchParam.jwnetItemTypeId"
                :validation="validateJustBlank"
              >
                <option value="">すべて</option>
                <option
                  v-for="(opt, index) in jwnetItemsOpt"
                  :key="`jwnet-opt-${index}`"
                  :value="opt.id"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
            <div>
              <span class="text-label">ルート番号</span>
              <InputText
                :value.sync="searchParam.routeId"
                inputType="number"
                placeholder="ルート番号を入力"
                :validation="validateNumberMaxLen"
                :validateArg="11"
              >
              </InputText>
            </div>
            <div>
              <span class="text-label">ルート名称</span>
              <InputText
                :value.sync="searchParam.routeName"
                inputType="text"
                placeholder="ルート名称を入力"
                :validation="validateMaxLen"
                :validateArg="50"
              >
              </InputText>
            </div>
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__items__cols search-wrap">
            <div>
              <span class="text-label">ステータス</span>
              <div class="l-flex wrap full">
                <div
                  v-for="(st, index) in statuses"
                  :key="index"
                  class="c-input"
                >
                  <div class="c-checkbox">
                    <input
                      :id="`st-is-status-${index}`"
                      type="checkbox"
                      v-model="searchParam.status"
                      :value="st.value"
                    />
                    <label
                      class="c-checkbox__label"
                      :for="`st-is-status-${index}`"
                    >
                      <span class="c-checkbox__box"></span>
                      {{ st.label }}
                      <span v-if="showcount(st.value) !== null">
                        {{ `(${showcount(st.value)})` }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-inputWrap">
          <span class="text-label"></span>
          <div class="l-flex wrap full-w -between">
            <div class="c-input">
              <div class="c-checkbox">
                <input
                    :id="`isMine-is-status`"
                    type="checkbox"
                    v-model="searchParam.isMine"
                />
                <label
                    class="c-checkbox__label"
                    :for="`isMine-is-status`"
                >
                  <span class="c-checkbox__box"></span>
                  自身IDのみ
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__items__cols search-wrap">
            <div>
              <span class="text-label">運搬終了報告</span>
              <Selectbox
                class="mid"
                :value.sync="searchParam.collectReportSearchType"
                :validation="validateJustBlank"
              >
                <option value="">指定なし</option>
                <option
                  v-for="selection in reportSelection.COLLECT_REPORT_SELECTION"
                  :key="`cba-collect-${selection.value}`"
                  :value="selection.value"
                >
                  {{ selection.name }}
                </option>
              </Selectbox>
            </div>
            <div>
              <span class="text-label">処分終了報告</span>
              <Selectbox
                class="mid"
                :value.sync="searchParam.disposalReportSearchType"
                :validation="validateJustBlank"
              >
                <option value="">指定なし</option>
                <option
                  v-for="selection in reportSelection.DISPOSAL_REPORT_SELECTION"
                  :key="`cba-disposal-${selection.value}`"
                  :value="selection.value"
                >
                  {{ selection.name }}
                </option>
              </Selectbox>
            </div>
            <div>
              <span class="text-label">最終処分終了報告</span>
              <Selectbox
                class="mid"
                :value.sync="searchParam.finalDisposalReportSearchType"
                :validation="validateJustBlank"
              >
                <option value="">指定なし</option>
                <option
                  v-for="selection in reportSelection.FINAL_DISPOSAL_REPORT_SELECTION"
                  :key="`cba-final-disposal-${selection.value}`"
                  :value="selection.value"
                >
                  {{ selection.name }}
                </option>
              </Selectbox>
            </div>
          </div>
        </div>

        <!-- 詳細のときだけ表示 -->
        <template v-if="isSearchDetail">

          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">マニフェスト区分</span>
                <div class="l-flex wrap full">
                  <div
                    v-for="(mt, index) in manifest_type"
                    :key="`mt-${index}`"
                    class="c-input"
                  >
                    <div class="c-checkbox">
                      <input
                        :id="`mt-is-status-${index}`"
                        type="checkbox"
                        v-model="searchParam.manifestType"
                        :value="mt.value"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="`mt-is-status-${index}`"
                      >
                        <span class="c-checkbox__box"></span>
                          {{ mt.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div
                  v-for="(list, index) in searchParam.generateStoreIdList"
                  :key="`t-slect-${index}`"
                  class="c-input"
              >
                <span class="text-label">
                  <p v-if="index === 0">排出事業場</p>
                </span>
                <div class="c-inputWrap__items__cols center search-wrap" :class="{ disabled: !searchParam.generateCompanyId }">
                  <v-autocomplete ref="generate_store_autocomplete" @change="s => {onChange(s, 'generate_store_autocomplete')}" placeholder="" 
                    class="c-input" :items="selectGenerateStoreList" :min-len="0" :wait="300" 
                    @update-items='(text) => updateGenerateStoreList(text)' 
                    v-bind:initItems="generateStoreList" 
                    v-model="selectedGenerateStore[index]" 
                    :component-item='selectTemplate' :auto-select-one-item="autoSelect" :get-label='getLabel'>
                  </v-autocomplete>
                  <span
                      v-if="searchParam.generateStoreIdList.length > 1 && index > 0"
                      class="c-btn-icon delete secondary small l-item-l"
                      @click="deleteGenerateStoreSelection(index)"/>
                </div>
              </div>
              <div
                  v-if="searchParam.generateStoreIdList.length < 5"
                  :class="{ disabled: !searchParam.generateCompanyId }"
                  class="c-btn secondary sm add l-item-l"
                  @click="addGenerateStoreSelection"
              >
                排出事業場を追加
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">運搬事業者</span>
                  <v-autocomplete ref="collect_autocomplete" @change="s => {onChange(s, 'collect_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectDisposalCollectList" :min-len="0" :wait="300" @update-items='updateDisposalCollectList' v-model="selectedDisposalCollect" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                  </v-autocomplete>
              </div>
              <div>
                <span class="text-label">処分場</span>
                  <v-autocomplete ref="disposal_autocomplete" @change="s => {onChange(s, 'disposal_autocomplete')}" placeholder="すべて" class="c-input" :items="this.selectDisposalSiteList" :min-len="0" :wait="300" @update-items='updateDisposalSiteList' v-model="selectedDisposalSite" :component-item='selectItemShowNameAndJwnetIdTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabelDisposalSite'>
                  </v-autocomplete>
              </div>
              <div>
                <span class="text-label">廃棄物区分</span>
                <div class="l-flex wrap full">
                  <div
                    v-for="(wt, index) in waste_type"
                    :key="index"
                    class="c-input"
                  >
                    <div class="c-checkbox">
                      <input
                        :id="`wt-is-status-${index}`"
                        type="checkbox"
                        v-model="searchParam.wasteType"
                        :value="wt.value"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="`wt-is-status-${index}`"
                      >
                        <span class="c-checkbox__box"></span>
                        {{ wt.label }}
                      </label>
                      <div v-if="wt.value == waste_type[0].value && searchParam.wasteType.includes(waste_type[0].value)">
                        <div class="l-flex wrap full">
                          <span>(</span>
                          <div
                            v-for="(jitf, index) in jwnet_item_type_field"
                            :key="index"
                            class="c-input"
                          >
                            <div class="c-checkbox">
                              <input
                                :id="`jitf-is-status-${index}`"
                                type="checkbox"
                                v-model="searchParam.jwnetItemTypeField"
                                :value="jitf.value"
                              />
                              <label
                                class="c-checkbox__label"
                                :for="`jitf-is-status-${index}`"
                              >
                                <span class="c-checkbox__box"></span>
                                {{ jitf.label }}
                              </label>
                            </div>
                          </div>
                          <span>)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">代理登録</span>
                <div class="l-flex wrap full">
                  <div class="c-input">
                    <div class="c-checkbox">
                      <input
                        :id="`isProxyRegist-is-status`"
                        type="checkbox"
                        v-model="searchParam.isProxyRegist"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="`isProxyRegist-is-status`"
                      >
                        <span class="c-checkbox__box"></span>
                        代理登録のみ
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">終了日/報告日検索対象</span>
                <div class="l-flex wrap full">
                  <div
                    v-for="(tdt, index) in term_date_type"
                    :key="index"
                    class="c-input"
                  >
                    <div class="c-checkbox">
                      <input
                        :id="`tdt-is-status-${index}`"
                        type="checkbox"
                        v-model="searchParam.termDateType"
                        :value="tdt.value"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="`tdt-is-status-${index}`"
                      >
                        <span class="c-checkbox__box"></span>
                        {{ tdt.label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">終了日</span>
                <div class="c-inputWrap__items__cols search-wrap">
                  <InputDate
                    :value.sync="searchParam.reportDateFrom"
                    placeholder="0000/00/00"
                  />
                  <span>〜</span>
                  <InputDate
                    :value.sync="searchParam.reportDateTo"
                    placeholder="0000/00/00"
                  />
                </div>
              </div>
              <div>
                <span class="text-label">報告日</span>
                <div class="c-inputWrap__items__cols search-wrap">
                  <InputDate
                    :value.sync="searchParam.registDateFrom"
                    placeholder="0000/00/00"
                  />
                  <span>〜</span>
                  <InputDate
                    :value.sync="searchParam.registDateTo"
                    placeholder="0000/00/00"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__items__cols search-wrap">
              <div>
                <span class="text-label">連絡番号</span>
                <InputText
                  :value.sync="searchParam.contactNo"
                  inputType="text"
                  placeholder="連絡番号を入力"
                  :validation="validateNumber"
                >
                </InputText>
              </div>
              <div>
                <span class="text-label">備考</span>
                <InputText
                  :value.sync="searchParam.remarks"
                  inputType="text"
                  placeholder="備考を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                </InputText>
              </div>
              <div>
                <span class="text-label">ノート</span>
                <InputText
                  :value.sync="searchParam.manifestLabel"
                  inputType="text"
                  placeholder="ノートを入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                </InputText>
              </div>
              <div>
                <span class="text-label"></span>
                <div class="l-flex wrap full ">
                  <div class="c-input">
                    <div class="c-checkbox">
                      <input
                      :id="`isOnlyEmptyRegist-is-status`"
                      type="checkbox"
                      v-model="searchParam.isActive"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="`isOnlyEmptyRegist-is-status`"
                      >
                      <span class="c-checkbox__box"></span>
                        削除は含めない
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="l-container">
      <div class="l-btns l-block-lg">
        <div class="c-btn secondary small" @click="resetSearchHandler">
          条件をリセット
        </div>
        <div class="c-btn primary small" @click="searchHandler">検索</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang='scss'>
  .full-w {
    width: 100%;
    align-items: flex-start;
  }
</style>

<script>
import InputText from "@/components/parts/input/InputText";
import InputDate from "@/components/parts/input/InputDate";
import Selectbox from "@/components/parts/input/Selectbox";
import SelectItemTemplate from "@/components/parts/input/SelectItemTemplate";
import validation from "@/mixin/validation";
import callApi from "../mixins/callApi";
import {DOMAIN, REPORT_SELECTION} from "@/const";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";
import SelectItemShowNameAndJwnetIdTemplate from "@/components/parts/input/SelectItemShowNameAndJwnetIdTemplate";

export default {
  components: {
    InputText,
    InputDate,
    Selectbox,
  },
  mixins: [validation, callApi, selectionLocalStorage],
  data() {
    return {
      selectedGeneratorCollect : '', //選択した運搬事業者
      selectedGeneratorDisposal: '', //選択した処分事業者
      selectedGeneratorCompany: '', //選択した排出事業者
      selectedGenerateStore: [null],
      selectedDisposalGenerate: '',
      selectedDisposalCollect: '',
      selectedDisposalSite: '',
      selectGenerateStoreList: [],
      generateStoreList: [],
      autoSelect: false,
      reportSelection: REPORT_SELECTION,
      isDisplaySearch: false,
      isSearchDetail: false,
      selectTemplate: SelectItemTemplate,
      selectItemShowNameAndJwnetIdTemplate: SelectItemShowNameAndJwnetIdTemplate,
      // 検索v-model
      searchParam: {
        wasteName: "",
        manifestNo: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        status: [],
        collectReportSearchType: "",
        disposalReportSearchType: "",
        finalDisposalReportSearchType: "",
        isMine: true,
        manifestType: [],
        generateStoreIdList: [],
        disposalStoreIdList: [],
        collectCompanyId: "",
        disposalCompanyId: "",
        disposalSiteId: "",
        generateCompanyId: "",
        wasteType: [],
        jwnetItemTypeField: [],
        termDateType: [],
        reportDateFrom: "",
        reportDateTo: "",
        registDateFrom: "",
        registDateTo: "",
        contactNo: "",
        remarks: "",
        manifestLabel: "",
        isActive: true,
        isProxyRegist: false,
      },
      statuses: [
        {
          label: "終了報告期限注意",
          value: DOMAIN.DISPOSAL_MANIFEST_SEARCH_TYPE.REPORT_DATE_ALERT,
        },
        {
          label: "終了報告期限切れ",
          value: DOMAIN.DISPOSAL_MANIFEST_SEARCH_TYPE.REPORT_DATE_EXPIRED,
        },
      ],
      manifest_type: [
        { value: DOMAIN.MANIFEST_TYPE.DIGITAL , label: "電子" },
        { value: DOMAIN.MANIFEST_TYPE.ANALOG , label: "紙マニフェスト" },
      ],
      waste_type: [
        { value: DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE , label: "産業廃棄物" },
        { value: DOMAIN.WASTE_TYPE.VALUABLES , label: "有価物" },
        { value: DOMAIN.WASTE_TYPE.COMMON_WASTE , label: "一般廃棄物" },
      ],
      jwnet_item_type_field: [
        { value: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_NOT_SPECIAL_INDUSTRIAL_WASTE , label: "普通産業廃棄物" },
        { value: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_SPECIAL_INDUSTRIAL_WASTE , label: "特別管理産業廃棄物" },
      ],
      term_date_type: [
        {
          label: "運搬",
          value: 1,
        },
        {
          label: "処分",
          value: 2,
        },
        {
          label: "最終処分",
          value: 3,
        },
      ],
      proxy_registration_type: [
        {
          label: "代理登録のみ",
          value: 4,
        },
      ],
      isLoadingStoreApi: true,
    };
  },

  methods: {
    onChange(queryString, ref) {
    this.$nextTick(() => {
        this.$refs[ref].searchText = queryString;
     });
    },
    showcount(statusVal) {
      let count = null;
      for (const sc of this.manifestStatusCount) {
        if (sc.manifestSearchTypeId === statusVal) {
          count = sc.count;
        }
      }
      return count;
    },

    initSearch(isAll = true) {
      this.selectedGeneratorCollect = ''
      this.selectedGeneratorDisposal = ''
      this.selectedGeneratorCompany = ''
      this.selectedGenerateStore = [null]
      this.selectedDisposalGenerate = isAll ? '' : this.selectedDisposalGenerate
      this.selectedDisposalCollect = ''
      this.selectedDisposalSite = ''
      this.searchParam = {
        wasteName: isAll ? "" : this.searchParam.wasteName,
        manifestNo: isAll ? "" : this.searchParam.manifestNo,
        collectionDateFrom: isAll ? "" : this.searchParam.collectionDateFrom,
        collectionDateTo: isAll ? "" : this.searchParam.collectionDateTo,
        cbaItemId: isAll ? "" : this.searchParam.cbaItemId,
        jwnetItemTypeId: isAll ? "" : this.searchParam.jwnetItemTypeId,
        routeId: isAll ? "" : this.searchParam.routeId,
        routeName: isAll ? "" : this.searchParam.routeName,
        status: isAll ? [] : this.searchParam.status,
        isMine: isAll ? true : this.searchParam.isMine,
        isProxyRegist: isAll ? false : this.searchParam.isProxyRegist,
        collectReportSearchType: isAll ? "" :  this.searchParam.collectReportSearchType,
        disposalReportSearchType: isAll ? "" : this.searchParam.disposalReportSearchType,
        finalDisposalReportSearchType: isAll ? "" : this.searchParam.finalDisposalReportSearchType,
        manifestType: [],
        generateStoreIdList: [null],
        generateStoreList: isAll ? [] : this.searchParam.generateStoreList,
        collectCompanyId: "",
        disposalCompanyId: "",
        generateCompanyId: isAll ? "" : this.searchParam.generateCompanyId,
        disposalSiteId: "",
        wasteType: [],
        jwnetItemTypeField:[],
        termDateType: [],
        reportDateFrom: "",
        reportDateTo: "",
        registDateFrom: "",
        registDateTo: "",
        issueDateFrom: "",
        issueDateTo: "",
        contactNo: "",
        remarks: "",
        manifestLabel: "",
        isActive: true,
      };
    },

    // 排出事業場の項目追加
    addGenerateStoreSelection() {
      //追加した項目の選択肢の初期化
      this.selectGenerateStoreList = this.generateStoreList
      this.selectedGenerateStore.push(null);
      this.searchParam.generateStoreIdList.push(null);
    },

    // 排出事業場の項目削除
    deleteGenerateStoreSelection(index) {
      this.selectedGenerateStore.splice(index,1);
      this.searchParam.generateStoreIdList.splice(index, 1);
    },

    resetSearchHandler() {
      this.initSearch();
      // this.$emit("search", this.searchParam); 条件リセット後の自動検索をやめる
    },

    searchHandler() {
      // ページの初期化
      this.$store.commit("searchParamManifestsAll/setManifestAllPage", 1);
      this.$emit("search", this.searchParam);
    },

    handleScroll() {
      this.scrollY = window.scrollY;
      if (!this.isDisplaySearch) {
        this.isDisplaySearch = window.scrollY > 200;
      } else if (window.scrollY < 190) {
        this.isDisplaySearch = !this.isDisplaySearch;
      }
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    shorten() {
      const result = [];
      const prev = this.$store.getters["searchParamManifestsAll/get"];

      if (prev.wasteName) result.push({ key: "廃棄物名称", value: prev.wasteName });
      if (prev.generateCompanyId) result.push({ key: "排出事業者", value: this.disposalGenerateList.filter(g => g.id === prev.generateCompanyId)[0].name });
      if (prev.collectCompanyId) result.push({ key: "運搬事業者", value: this.disposalCollectList.filter(c => c.id === prev.collectCompanyId)[0].name });
      if (prev.disposalSiteId) result.push({ key: "処分場", value: this.disposalSiteList.filter(d => d.id === prev.disposalSiteId)[0].name });
      if (prev.collectionDateFrom || prev.collectionDateTo) result.push({ key: "引き渡し日", value: prev.collectionDateFrom + "〜" + prev.collectionDateTo });
      if (prev.status.length) {
        result.push({
          key: "ステータス",
          value: this.statuses
              .filter(scb => prev.status.includes(scb.value))
              .map(scb => scb.label).join("、")
        });
      }
      if (prev.manifestType.length) {
        result.push({ 
          key: "マニフェスト区分", 
          value: this.manifest_type
              .filter(scb => prev.manifestType .includes(scb.value))
              .map(scb => scb.label).join("、")
          });
      }
      if (prev.generateStoreIdList[0]) {
        result.push({
          key: "排出事業場",
          value: this.storeList
              .flatMap(c => c.store)
              .filter(s => prev.generateStoreIdList.filter(gs => gs === s.id).length)
              .map(s => s.name).join("、")
        });
      }
      if (prev.disposalCompanyId) result.push({ key: "処分事業者", value: this.generatorDisposalList.filter(d => d.id === prev.disposalCompanyId)[0].name });
      if (prev.manifestNo) result.push({ key: "マニフェスト番号", value: prev.manifestNo });
      if (prev.contactNo) result.push({ key: "連絡番号", value: prev.contactNo });
      if (prev.jwnetItemTypeId) result.push({ key: "廃棄物種類", value: this.jwnetItemsOpt.filter(j => j.id === prev.jwnetItemTypeId)[0].name });
      if (prev.cbaItemId) result.push({ key: "CBA品目", value: this.cbaItems.filter(c => c.id === prev.cbaItemId)[0].name });
      if (prev.routeName) result.push({ key: "ルート名称", value: prev.routeName });
      if (prev.routeId) result.push({ key: "ルート番号", value: parseInt(prev.routeId)});
      if (prev.wasteType.length) {
        result.push({ 
          key: "廃棄物区分", 
          value: this.waste_type
              .filter(scb => prev.wasteType .includes(scb.value))
              .map(scb => scb.label).join("、")
          });
      }
      if (prev.jwnetItemTypeField.length) result.push({ 
        key: "廃棄物種類区分", 
        value: this.jwnet_item_type_field
            .filter(it => prev.jwnetItemTypeField.includes(it.value))
            .map(it => it.label).join("、") });
      if (prev.termDateType.length) {
        result.push({ 
          key: "終了日/報告日検索対象", 
          value: this.term_date_type
              .filter(scb => prev.termDateType .includes(scb.value))
              .map(scb => scb.label).join("、")
          });
      }
      if (prev.reportDateFrom || prev.reportDateTo) result.push({ key: "引き渡し日", value: prev.reportDateFrom + "〜" + prev.reportDateTo });
      if (prev.registDateFrom || prev.registDateTo) result.push({ key: "引き渡し日", value: prev.registDateFrom + "〜" + prev.registDateTo });
      if (prev.remarks) result.push({ key: "備考", value: prev.remarks });
      if (prev.manifestLabel) result.push({ key: "ノート", value: prev.manifestLabel });
      if (prev.isMine) result.push({ key: "自身IDのみ", value: prev.isMine });
      if (prev.isProxyRegist) result.push({ key: "代理登録のみ", value: prev.isProxyRegist });
      if (prev.isActive) result.push({ key: "削除を含めない", value: prev.isActive });
      if (prev.collectReportSearchType) result.push({ key: "運搬終了報告", value: this.reportSelection.COLLECT_REPORT_SELECTION.filter(it => it.value === prev.collectReportSearchType)[0].name});
      if (prev.disposalReportSearchType) result.push({ key: "処分終了報告", value: this.reportSelection.DISPOSAL_REPORT_SELECTION.filter(it => it.value === prev.disposalReportSearchType)[0].name});
      if (prev.finalDisposalReportSearchType) result.push({ key: "最終処分終了報告", value: this.reportSelection.FINAL_DISPOSAL_REPORT_SELECTION.filter(it => it.value === prev.finalDisposalReportSearchType)[0].name});
      return result;
    },
    updateGeneratorCollectList (text) {
      if (!text || text.length === 0) {
        //全ての運搬事業者の選択肢を表示する
        this.selectGeneratorCollectList = this.generatorCollectList
        return
      }
      this.selectGeneratorCollectList = this.generatorCollectList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGeneratorDisposalList (text) {
      if (!text || text.length === 0) {
        //全ての処分事業者の選択肢を表示する
        this.selectGeneratorDisposalList = this.generatorDisposalList
        console.log(this.selectGeneratorDisposalList);
        return
      }
      this.selectGeneratorDisposalList = this.generatorDisposalList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateDisposalGenerateList (text) {
      if (!text || text.length === 0) {
        this.selectDisposalGenerateList = this.disposalGenerateList
        console.log(this.selectDisposalGenerateList);
        return
      }
      this.selectDisposalGenerateList = this.disposalGenerateList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })

    },
    updateDisposalCollectList (text) {
      if (!text || text.length === 0) {
        this.selectDisposalCollectList = this.disposalCollectList
        console.log(this.selectDisposalCollectList);
        return
      }
      this.selectDisposalCollectList = this.disposalCollectList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateDisposalSiteList (text) {
      if (!text || text.length === 0) {
        this.selectDisposalSiteList = this.disposalSiteList
        console.log(this.selectDisposalSiteList);
        return
      }
      this.selectDisposalSiteList = this.disposalSiteList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGeneratorCompanyList (text) {
      if (!text || text.length === 0) {
        //全ての排出事業者の選択肢を表示する
        this.selectGeneratorCompanyList = this.generatorCompanyList
        return
      }
      this.selectGeneratorCompanyList = this.generatorCompanyList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGenerateStoreList (text) {
      if (!text || text.length === 0) {
        //全ての排出事業場を表示する
        this.selectGenerateStoreList = this.generateStoreList
        return
      }
      this.selectGenerateStoreList = this.generateStoreList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },

    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
    getLabelDisposalSite (item) {
      if (item) {
        return item.name + "  JWNETID:"+ item.jwnetId;
      }
      return ''
    },
  },
  watch: {
    selectedGeneratorCollect: function(newVal) {
      this.searchParam.collectCompanyId = newVal ? newVal.id : ''
    },
    selectedGeneratorDisposal: function(newVal) {
      this.searchParam.disposalCompanyId = newVal ? newVal.id : ''
    },
    selectedGeneratorCompany: function(newVal) {
      this.searchParam.generateCompanyId = newVal ? newVal.id : ''
    },
    selectedDisposalGenerate: function(newVal) {
      this.selectedGenerateStore = [null];
      this.searchParam.generateCompanyId = newVal ? newVal.id : ''
      if (this.searchParam.generateCompanyId) {
        this.generateStoreList = this.storeList.filter(
            (st) => st.companyId == this.searchParam.generateCompanyId
        ).map(f => f.store)
        this.selectGenerateStoreList = this.storeList.filter(
            (st) => st.companyId == this.searchParam.generateCompanyId
        ).map(f => f.store)
      }
      this.searchParam.generateStoreList = this.generateStoreList
    },
    selectedGenerateStore: function(newVal) {
      if (!this.isLoadingStoreApi) {
        newVal.forEach( (e, i) => {
            this.searchParam.generateStoreIdList[i] = e ? e.id : null
          })
      }
    },

    selectedDisposalCollect: function(newVal) {
      this.searchParam.collectCompanyId = newVal ? newVal.id : ''
    },
    selectedDisposalSite: function(newVal) {
      this.searchParam.disposalSiteId = newVal ? newVal.id : ''
    },
  },

  mounted() {
    this.searchParam = this.$store.getters["searchParamManifestsAll/get"];
    this.isSearchDetail = this.$route.query.transitionSourceHome ? true :  this.$store.getters["searchParamManifestsAll/isSearchDetail"];
    window.addEventListener("scroll", this.handleScroll);

    this.getJwnetItemsApi();
    this.getCbaItemsApi();
    this.getDisposalGenerateSelectcompanyApi();
    this.getDisposalCollectSelectcompanyApi();
    this.getSelectoptionDisposalsiteApi();
    this.getSelectoptionGenerateStoreApi();
    this.getDisposerManifestCountApi();
  },
};
</script>
