<template>
  <div>
    <div v-if="manifestList.length" class="l-flex cols l-item">
      <div class="u-txt-bold">選択中 {{ checkCount }} 件</div>
      <div class="l-flex cols" :class="{ disabled: !checkCount }">
        <div class="c-btn primary small" :class="{ disabled: isCallApiProcess }" @click="clickReserveHandler">予約</div>
        <div class="c-btn primary small" :class="{ disabled: isCallApiProcess }" @click="clickIssueHandler">交付</div>
        <div class="c-btn primary small" @click="clickPrintHandler">受渡確認票印刷</div>
        <div class="c-btn secondary small" :class="{ disabled: checkedUnderReserveCount || isCallApiProcess || sealedManifestCount}" @click="clickCancelHandler">取消</div>
      </div>
      <div class="c-btn secondary small upload" style="margin-left: auto;!important;width: 16rem;" @click="onClickCsvUploadModalDisplayButton">CSVアップロード</div>
      <div class="c-btn secondary small download" :class="{ disabled: isCallApiProcess }" style="width: 16rem;" @click="clickCSVSearchHandler">CSVダウンロード</div>
    </div>

    <div class="c-infoList__wrap">
      <div class="c-infoList scroll has_calendar l-box">
        <div class="c-infoList__row head">
          <div class="c-infoList__cell cb">
            <div class="c-checkbox">
              <input
                id="check-all-manifests"
                type="checkbox"
                v-model="isTableAllCheck"
                @change="changeAllCheckHandler"
              />
              <label class="c-checkbox__label" for="check-all-manifests">
                <span class="c-checkbox__box"></span>
              </label>
            </div>
          </div>
          <div class="c-infoList__cell md-s">マニフェスト番号</div>
          <div class="c-infoList__cell sm">ステータス</div>
          <div class="c-infoList__cell full-date">
            <a @click="clickSortChangeHandler">
              <span
                  class="c-infoList__cell__sort"
                  :class="{
                  asc: sortParams.type.toLowerCase() === 'asc',
                  desc: sortParams.type.toLowerCase() === 'desc',
                }"
              >引き渡し日</span>
            </a>
          </div>
          <div class="c-infoList__cell sm">廃棄物区分</div>
          <div class="c-infoList__cell sm">廃棄物名称</div>
          <div class="c-infoList__cell sm">詳細名称</div>
          <div class="c-infoList__cell md">数量</div>
          <div class="c-infoList__cell sm">確定数量</div>
          <div class="c-infoList__cell sm">排出事業場</div>
          <div class="c-infoList__cell md">運搬事業者</div>
          <div class="c-infoList__cell sm">処分場</div>
          <div class="c-infoList__cell cb"></div>
        </div>

        <!-- 検索結果あり -->
        <template v-if="manifestList.length">
          <template v-for="(manifest, index) of manifestList">
            <div class="c-infoList__row list" :class="{ 'inline-edit-mode': manifest.isEdit, 'highlight-line': checkHighlightLine(manifest.manifestId) }" :key="'manifest-' + index">
              <div class="c-infoList__cell cb text-dotted">
                <!-- チェックボックス -->
                <div class="c-checkbox">
                  <input
                      :id="'checkbox-' + index"
                      type="checkbox"
                      v-model="manifest.isChecked"
                      @click="changeManifestCbHandler(index, manifest)"
                  />
                  <label class="c-checkbox__label" :for="'checkbox-' + index">
                    <span class="c-checkbox__box"></span>
                  </label>
                </div>
              </div>
              <!-- マニフェスト番号 -->
              <div
                  @click="toDetail(manifest.manifestId)"
                  class="c-infoList__cell md-s c-tooltip">
                <router-link :to="`/manifest/${manifest.manifestId}`">
                  {{ manifest.manifestNo | blankText }}
                  {{ manifest.manifestRegistType == manifestRegistType.COLLECTOR_REGIST ?  "（運代）" : "" }}
                  {{ manifest.manifestRegistType == manifestRegistType.DISPOSER_REGIST ? "（処代）" : "" }}
                </router-link>
                <div class="c-status info-red"
                     v-if="manifest.isFinalDisposalSiteNG"
                >
                  <div class="c-infoList__popup">
                    <p>最終処分場チェックエラーです</p>
                  </div>
                </div>
              </div>
              <!-- ステータス -->
              <div class="c-infoList__cell sm text-dotted"
                   @click="toDetail(manifest.manifestId)"
              >
                {{ manifest.manifestStatusType == null ? "": manifest.manifestStatusType.name }}
              </div>

              <!-- 引き渡し日 -->
              <div class="c-infoList__cell full-date c-tooltip" @click="toDetail(manifest.manifestId)">
                <div class="c-infoText">
                  <div>
                    <template v-if="manifest.isEdit">
                      <InputDate class="inline-edit-mode"
                        :value.sync="manifest.editCollectionDate"
                        placeholder="0000/00/00"
                        :validation="validateJustBlank"
                        :isValid.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editCollectionDate.isValid"
                        :errMsg.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editCollectionDate.errMsg"
                      />
                    </template>
                    <template v-else>
                      {{ manifest.collectionDate | dateJpYMDddFormat }}
                    </template>
                  </div>
                </div>
                <div v-if="manifest.collectionDateLimit" class="c-status" :class="manifest.collectionDateLimit">
                  <div class="c-infoList__popup">
                    <span v-if="manifest.collectionDateLimit === limitStatus.INFO">交付時は入力が必要です<br></span>
                    <span v-if="manifest.collectionDateLimit === limitStatus.WARNING">交付期限が迫っています<br></span>
                    <span v-if="manifest.collectionDateLimit === limitStatus.DANGER">交付期限を超過しています<br></span>
                  </div>
                </div>
              </div>

              <!-- 廃棄物区分 -->
              <div
                  @click="toDetail(manifest.manifestId)"
                  class="c-infoList__cell sm text-dotted">
                {{ manifest.wasteType != null ? manifest.wasteType.name : "" }}
              </div>
              <!-- 廃棄物名称 -->
              <div
                  @click="toDetail(manifest.manifestId)"
                  class="c-infoList__cell sm text-dotted">
                {{ manifest.wasteName != null ? manifest.wasteName.name : "" }}
              </div>
              <!-- 詳細名称 -->
              <div
                  @click="toDetail(manifest.manifestId)"
                  class="c-infoList__cell sm text-dotted">
                {{ manifest.detailWasteName != null ? manifest.detailWasteName : "" }}
              </div>

              <!-- 数量 -->
              <div class="c-infoList__cell md c-tooltip" @click="toDetail(manifest.manifestId)">
                <div class="c-infoText">
                  <div>
                    <template v-if="manifest.isEdit">
                      <div class="c-inputWrap__items__cols search-wrap inline-edit-mode">
                        <InputText class="sm inline-edit-mode"
                          inputType="number"
                          :value.sync="manifest.editWasteQuantity"
                          :validation="validateNumberOrFloat"
                          :isValid.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editWasteQuantity.isValid"
                          :errMsg.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editWasteQuantity.errMsg"
                        />
                        <Selectbox class="sm inline-edit-mode"
                            :value.sync="manifest.editWasteQuantityUnitTypeId"
                            :validation="validateJustBlank"
                            :isValid.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editWasteQuantityUnitTypeId.isValid"
                            :errMsg.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editWasteQuantityUnitTypeId.errMsg"
                        >
                          <option :value="null"></option>
                          <option
                              v-for="(opt, index) in wasteUnitOpt"
                              :key="'wu' + index"
                              :value="opt.id"
                          >
                            {{ opt.name }}
                          </option>
                        </Selectbox>
                      </div>
                    </template>
                    <template v-else>
                      <template v-if="!manifest.wasteQuantity || !manifest.wasteUnitType">-</template>
                      <template v-else>{{ manifest.wasteQuantity | formatFloat }} {{ manifest.wasteUnitType.name }}</template>
                    </template>
                  </div>
                </div>
                <div v-if="!manifest.wasteQuantity || !manifest.wasteUnitType" class="c-status info">
                  <div class="c-infoList__popup">
                    <span>交付時は入力が必要です<br></span>
                  </div>
                </div>
              </div>

              <!-- 確定数量 -->
              <div
                  @click="toDetail(manifest.manifestId)"
                  class="c-infoList__cell sm text-dotted">
                <template v-if="!manifest.fixedWasteQuantity || !manifest.fixedWasteQuantityUnitType">-</template>
                <template v-else>{{ manifest.fixedWasteQuantity | formatFloat }} {{ manifest.fixedWasteQuantityUnitType.name }}</template>
              </div>
              <!-- 排出事業者 -->
              <div
                  @click="toDetail(manifest.manifestId)"
                  class="c-infoList__cell sm text-dotted">
                {{ manifest.generateStore.name | blankText }}
              </div>
              <!-- 運搬事業者 -->
              <div
                  @click="toDetail(manifest.manifestId)"
                  class="c-infoList__cell md c-tooltip"
              >
                <CommaText :items="manifest.collectCompany" arg="name" :maxLength="11" />
              </div>
              <!-- 処分場 -->
              <div
                  @click="toDetail(manifest.manifestId)"
                  class="c-infoList__cell sm text-dotted">
                {{ manifest.disposalSite == null ? "": manifest.disposalSite.name }}
              </div>
              <div class="c-infoList__cell cb"></div>
            </div>
          </template>
        </template>

        <!-- 検索結果なし -->
        <template v-else>
          <div class="c-infoList__row list">
            <div class="c-infoList__cell">
              検索条件に一致するマニフェストはありません。
            </div>
          </div>
        </template>
      </div>

      <div v-if="manifestList.length" class="c-infoList__floated">
        <div
            v-for="(manifest, editIndex) of manifestList"
            :key="'edit-' + editIndex"
            class="c-infoList__cell"
        >
          <template v-if="((manifest.manifestStatusType.id === manifestStatus.RESERVED || manifest.manifestStatusType.id === manifestStatus.NOT_RESERVE) && manifest.routeId !== null)">
                <span class="c-infoList__cell" :class="{ 'inline-edit': !manifest.isEdit, 'eraser': manifest.isEdit }"
                      @click="clickEditHandler(editIndex, manifest)"></span>
          </template>
        </div>
      </div>
    </div>

    <!-- 一括編集 -->
    <div v-if="isBulkEdit">
      <div class="l-btns l-container">
        <div class="center float">
          <div class="c-btn secondary small" @click="clickCancelBulkEditHandler">キャンセル</div>
          <div class="c-btn primary mid" :class="{ disabled: isCallApiProcess }" @click="clickSubmitBulkEditHandler">保存</div>
        </div>
      </div>
    </div>

    <ManifestCsvUploadModal v-if="isDisplayCsvUploadModal" 
      v-bind:onClickCloseButton="hideCsvUploadModal"
      v-bind:onClickUploadButton="onClickUploadCsv"
      v-bind:onClickUploadRegisterCsvButton="onClickUploadRegisterCsv"
    />

  </div>
  <!-- table -->
</template>

<script>
import {DATE_LIMIT_STATUS} from "@/const/dateLimit";
import InputText from "@/components/parts/input/InputText";
import InputDate from "@/components/parts/input/InputDate";
import CommaText from "@/components/parts/CommaText";
import {DOMAIN} from "@/const";
import validation from "@/mixin/validation";
import callApi from "../mixins/callApi";
import manifestCallApi from "../../mixins/callApi";
import ManifestCsvUploadModal from "../../components/ManifestCsvUploadModal";
import Selectbox from "@/components/parts/input/Selectbox";

export default {
  components: {
    InputDate, 
    InputText, 
    ManifestCsvUploadModal, 
    Selectbox, 
    CommaText
  },
  mixins: [validation, callApi, manifestCallApi],
  props: {
    manifestList: {
      type: Array,
    },
    sortParams: {
      type: Object,
    },
    checkCount: {
      type: Number,
    },
    isAllChecked: {
      type: Boolean,
    },
    isBulkEdit: {
      type: Boolean,
    },
    checkedUnderReserveCount: {
      type: Number,
    },
    isCallApiProcess: {
      type: Boolean,
    },
    wasteUnitOpt: {
      type: Array,
    },
    validate: {
    },
    bundleIssueErrorList: {
      type: Array,
    },
    paginationParams: {
      type: Object
    },
    sealedManifestCount: {
      type: Number
    }
  },

  data() {
    return {
      isTableAllCheck: false,
      manifestStatus: DOMAIN.MANIFEST_STATUS,
      limitStatus: DATE_LIMIT_STATUS,
      manifestRegistType: DOMAIN.MANIFEST_REGIST_TYPE,
      isDisplayCsvUploadModal: false,
    };
  },

  methods: {
    // マニフェスト全件チェックボックス
    changeAllCheckHandler() {
      if (this.isTableAllCheck) {
        this.$emit("clickAllCheck", true);
      } else {
        this.$emit("clickAllCheck", false);
      }
    },

    // 各マニフェストのチェックボックス
    changeManifestCbHandler(selectedIndex, manifest) {
      this.$emit("clickEachCb", selectedIndex, manifest);
    },

    // 引き渡し日ソート変更
    clickSortChangeHandler() {
      const newSortType = this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";
      this.$emit("clickSortChange", newSortType);
    },

    // ペンアイコン押下
    clickEditHandler(selectedIndex, manifest) {
      manifest.isEdit = !manifest.isEdit;
      manifest.editCollectionDate = manifest.collectionDate;
      manifest.editWasteQuantity = manifest.wasteQuantity;
      manifest.editWasteQuantityUnitTypeId = manifest.wasteUnitType ? manifest.wasteUnitType.id : null;
      this.$set(this.manifestList, selectedIndex, manifest);
      if (manifest.isEdit) {
        this.$emit("pushEditValidate", manifest.manifestId);
      } else {
        this.$emit("removeEditValidate", manifest.manifestId);
      }

    },

    // 一括編集キャンセル
    clickCancelBulkEditHandler() {
      this.$emit("clickCancelBulkEdit");
    },

    // 一括編集確定
    clickSubmitBulkEditHandler() {
      this.$emit("clickSubmitBulkEdit");
    },

    // 交付押下
    clickIssueHandler() {
      this.$emit("clickIssue");
    },

    // 予約押下
    clickReserveHandler() {
      this.$emit("clickReserve");
    },

    // 受渡確認票印刷押下
    clickPrintHandler() {
      this.$emit("clickPrint");
    },

    // CSVダウンロード押下
    clickCSVSearchHandler() {
      this.$emit("clickCSVDownload");
    },

    // 取消押下 manifestStatusId: 90は「削除」
    clickCancelHandler() {
      this.$emit("clickCancel");
    },

    toDetail(manifestId) {
      this.setManifestAllPage()
      if (this.manifestList.filter(m => m.isEdit).length) return;
      const transitionSource = location.pathname;
      this.$router.push(`/manifest/${manifestId}?transitionSource=${transitionSource}`);
    },
    hideCsvUploadModal() {
      this.isDisplayCsvUploadModal = false;
    },
    onClickCsvUploadModalDisplayButton() {
      this.isDisplayCsvUploadModal = true;
    },

    onClickUploadCsv(csvFile) {
      return this.postManifestCsvupload(csvFile)
        .then(this.onUpload);
    },

    onClickUploadRegisterCsv(csvFile) {
      return this.postManifestCsvregister(csvFile)
        .then(this.onUpload);
    },

    onUpload() {
      this.hideCsvUploadModal();
      this.$emit('onUploadCsv');
    },
    // 一括交付条件に満たしていないデータをハイライトする
    checkHighlightLine(id) {
      return this.bundleIssueErrorList.find(n => n == id);
    },
    setManifestAllPage() {
      this.$store.commit("searchParamManifestsAll/setManifestAllPage", this.paginationParams.page);
    },
  },

  watch: {
    isAllChecked() {
      this.isTableAllCheck = this.isAllChecked;
    },
  },
};
</script>
<style lang="scss" scoped>
.c-infoList__cell p{
  margin-left: 5px
}
</style>