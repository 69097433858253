import axios from "axios";
import { API_URL, DOMAIN } from "@/const";
import { INT_MIN, INT_MAX } from "@/const/index";

export default {
  methods: {
    // URL生成
    getSearchApiUrl() {
      const params = new URLSearchParams();
      params.append("page", this.paginationParams.page);
      params.append("pageSize", this.paginationParams.pageSize);
      params.append("sort", `${this.sortParams.column}:${this.sortParams.type}`);
      params.append("searchConditionId", String(DOMAIN.MANIFEST_SEARCH_CONDITION.ALL));
      if (this.searchedParam.wasteName) params.append("wasteName", this.searchedParam.wasteName);
      if (this.searchedParam.collectCompanyId) params.append("collectCompanyId", this.searchedParam.collectCompanyId);
      if (this.searchedParam.generateCompanyId) params.append("generateCompanyId", this.searchedParam.generateCompanyId);
      if (this.searchedParam.collectionDateFrom) params.append("collectionDateFrom", this.searchedParam.collectionDateFrom);
      if (this.searchedParam.collectionDateTo) params.append("collectionDateTo", this.searchedParam.collectionDateTo);
      if (this.searchedParam.disposalCompanyId) params.append("disposalCompanyId", this.searchedParam.disposalCompanyId);
      if (this.searchedParam.disposalSiteId) params.append("disposalSiteId", this.searchedParam.disposalSiteId);
      if (this.searchedParam.manifestNo) params.append("manifestNo", this.searchedParam.manifestNo);
      if (this.searchedParam.contactNo) params.append("contactNo", this.searchedParam.contactNo);
      if (this.searchedParam.jwnetItemTypeId) params.append("jwnetItemTypeId", this.searchedParam.jwnetItemTypeId);
      if (this.searchedParam.cbaItemId) params.append("cbaItemId", this.searchedParam.cbaItemId);
      if (this.searchedParam.routeName) params.append("routeName", this.searchedParam.routeName);
      if (this.searchedParam.routeId) params.append("routeId", this.searchedParam.routeId);
      if (this.searchedParam.reportDateFrom) params.append("reportDateFrom", this.searchedParam.reportDateFrom);
      if (this.searchedParam.reportDateTo) params.append("reportDateTo", this.searchedParam.reportDateTo);
      if (this.searchedParam.registDateFrom) params.append("registDateFrom", this.searchedParam.registDateFrom);
      if (this.searchedParam.registDateTo) params.append("registDateTo", this.searchedParam.registDateTo);
      if (this.searchedParam.remarks) params.append("remarks", this.searchedParam.remarks);
      if (this.searchedParam.manifestLabel) params.append("manifestLabel", this.searchedParam.manifestLabel);
      if (this.searchedParam.isMine) params.append("isMine", this.searchedParam.isMine);
      if (this.searchedParam.isProxyRegist) params.append("isProxyRegist", this.searchedParam.isProxyRegist);
      if (this.searchedParam.isActive) params.append("isActive", this.searchedParam.isActive);
      if (this.searchedParam.collectReportSearchType) params.append("collectReportSearchType", this.searchedParam.collectReportSearchType);
      if (this.searchedParam.disposalReportSearchType) params.append("disposalReportSearchType", this.searchedParam.disposalReportSearchType);
      if (this.searchedParam.finalDisposalReportSearchType) params.append("finalDisposalReportSearchType", this.searchedParam.finalDisposalReportSearchType);
 
      if (this.searchedParam.generateStoreIdList) {
        this.searchedParam.generateStoreIdList.forEach(storeId => {
          if (storeId) { params.append("generateStoreId", storeId)};
        });
      }
      if (this.searchedParam.manifestType) {
        this.searchedParam.manifestType.forEach((s) => {
          params.append("manifestTypeList", s)
        });
      } 
      if (this.searchedParam.wasteType) {
        this.searchedParam.wasteType.forEach((s) => {
          params.append("wasteTypeList", s)
        });
      } 
      if (this.searchedParam.jwnetItemTypeField) {
        this.searchedParam.jwnetItemTypeField.forEach((s) => {
          params.append("jwnetItemTypeFieldList", s)
        });
      }      
      if (this.searchedParam.termDateType) {
        this.searchedParam.termDateType.forEach((s) => {
          params.append("termDateTypeList", s)
        });
      }
      if (this.searchedParam.status) {
        this.searchedParam.status.forEach((s) => {
          const paramLable = this.isCollector ? "collectManifestSearchType" : "manifestSearchType"
          params.append(paramLable, s)
        });
      }

      // 運搬マニフェストのみの条件
      if (this.isCollector) {
        if (this.searchedParam.collectionReportDateFrom) params.append("collectionReportDateFrom", this.searchedParam.collectionReportDateFrom);
        if (this.searchedParam.collectionReportDateTo) params.append("collectionReportDateTo", this.searchedParam.collectionReportDateTo);
      }

      if (this.isDisposer) {
        params.delete("searchConditionId")
      }

      if (this.isGenerator) {
        return API_URL.MANIFEST.SEARCH + '?' + params.toString();
      } else if (this.isCollector) {
        return API_URL.MANIFEST.SEARCH_BY_COLLECT + '?' + params.toString();
      } else if (this.isDisposer) {
        return API_URL.MANIFEST.SEARCH_BY_DISPOSAL + '?' + params.toString();
      }
    },

    // マニフェスト検索
    getManifestSearchApi(searchParam, isPaging) {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];
      if (parseInt(searchParam.routeId) < INT_MIN || parseInt(searchParam.routeId) > INT_MAX) {
        this.manifestList = [];
        return;
      }
      this.isAllChecked = false;
      if (!isPaging) {
        this.searchedParam = JSON.parse(JSON.stringify(searchParam)); // deep copy
        this.paginationParams.page = this.$store.getters["searchParamManifestsAll/getManifestAllPage"]
      }
      return axios
        .get(this.getSearchApiUrl())
        .then((res) => {
          this.manifestList = res.data.results;
          this.paginationParams.total = res.data.searchParams.pagination.total;
          this.sortParams = res.data.searchParams.sorts[0];
          this.$store.commit("searchParamManifestsAll/set", this.searchedParam);
          const currentParam = this.$route.query
          const requestObj = { 'page' : parseInt(this.paginationParams.page) }
          this.addRequestToLocation(Object.assign(currentParam, requestObj))
        })
        .then(() => {
          this.manifestList.map((manifest) => {
            if (manifest.wasteType != null && manifest.wasteType.id == DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE) {
              if (manifest.manifestType != null && manifest.manifestType.id == DOMAIN.MANIFEST_TYPE.DIGITAL) {
                manifest.wasteType.name = "産業廃棄物/電子"
              }
              if (manifest.manifestType != null && manifest.manifestType.id == DOMAIN.MANIFEST_TYPE.ANALOG) {
                manifest.wasteType.name = "産業廃棄物/紙"
              }
            }
            manifest.isChecked = false; // チェックの初期化
            manifest.isEdit = false; // 編集状態の初期化
          });
        })
        .catch((err) => {
          this.manifestList = [];
          console.log(err);
        });
    },

    // 廃棄物数量単位選択肢取得API    API_URL.DOMAIN.WASTE_UNIT
    getWasteUnitApi() {
      axios
        .get(API_URL.DOMAIN.WASTE_UNIT)
        .then((res) => {
          this.wasteUnitOpt = res.data.wasteUnitTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
