export default {
  data() {
    return {
      validate: {
        manifestList: [],
      },

      dummyValidate: {
        manifestId: null,
        editCollectionDate: { isValid: true, errMsg: "" },
        editWasteQuantity: { isValid: true, errMsg: "" },
        editWasteQuantityUnitTypeId: { isValid: true, errMsg: "" },
      }
    };
  },
  methods: {
    validatesCheck() {
      let isValidForm = true;
      this.validate.manifestList.forEach(v => {
        if (!v.editCollectionDate.isValid) {
          v.editCollectionDate.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.editWasteQuantity.isValid) {
          v.editWasteQuantity.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        const manifest = this.manifestList.find(m => m.manifestId === v.manifestId)
        if (manifest && manifest.isEdit) {
          if (!manifest.editWasteQuantity && manifest.editWasteQuantityUnitTypeId) {
            v.editWasteQuantity.errMsg = "数量と単位は両方入力してください。";
            isValidForm = false;
          }
          if (manifest.editWasteQuantity && !manifest.editWasteQuantityUnitTypeId) {
            v.editWasteQuantityUnitTypeId.errMsg = "数量と単位は両方入力してください。";
            isValidForm = false;
          }
          if (!manifest.editWasteQuantity && !manifest.editWasteQuantityUnitTypeId) {
            v.editWasteQuantity.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
          if (manifest.editWasteQuantity) {
            const wasteQuantityRegexp = new RegExp(/^(\d{1,5}|0)(\.\d{1,3})?$/);
            if (!wasteQuantityRegexp.test(manifest.editWasteQuantity)) {
              v.editWasteQuantity.errMsg =
                "整数5桁、小数点1桁、小数部3桁で入力してください。";
              isValidForm = false;
            }
            if (manifest.editWasteQuantity == 0.0) {
              v.editWasteQuantity.errMsg = "正しい値を入力してください。";
              isValidForm = false;
            }
          }
        }

      })

      return isValidForm;
    },

    runValidate() {
      return this.validatesCheck();
    },
  },
};
