import axios from "axios";
import { API_URL, DOMAIN } from "@/const";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";

export default {
  mixins: [ selectionLocalStorage ],
  data() {
    return {
      storeList: [],
      selectGeneratorCollectList: [], //入力テキストで絞る排出事業者選択肢
      generatorCollectList: [], 
      jwnetItemsOpt: [],
      selectGeneratorDisposalList: [], // 排出用、入力テキストで絞る処分事業者選択肢
      generatorDisposalList: [], //排出用、排出事業者選択肢
      selectCollectorDisposalList: [], //運搬用、入力テキストで絞る処分事業者選択肢
      collectorDisposalList: [], //運搬用、排出事業者選択肢
      cbaItems: [],
      manifestStatusCount: [],
      generateCompanyList: [],
      disposalCompanyList: [],
      storeListDisposal: [], //処分事業場
      disposalGenerateList: [],
      disposalCollectList: [],
      selectDisposalGenerateList: [],
      selectDisposalCollectList: [],
      generateStoreList: [],
      disposalSiteList: [], // 処分場の選択肢
      selectDisposalSiteList: [], // 入力テキストで絞った処分場の選択肢
      generatorCompanyList: [], // 排出事業者の選択肢
      selectGeneratorCompanyList: [], // 入力テキストで絞った排出事業者の選択肢
    };
  },
  methods: {
    // 事業場選択肢取得API
    getStoreListApi() {
      return axios
        .get(API_URL.SCHEDULE.GENERATE_STORE)
        .then((res) => {
          this.storeList = res.data.generateCompanyList;
          while (this.selectedGenerateStore.length < this.searchParam.generateStoreIdList.length) {
            this.selectedGenerateStore.push(null);
          }
          this.searchParam.generateStoreIdList.forEach( (c, index) => {
            this.selectedGenerateStore[index] = c ? this.generateStoreList.find(e => e.id === c) : null
          })
          this.isLoadingSearchGenerateStore = false
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 運搬事業者選択肢取得API
    getGeneratorCollectApi() {
      this.getGeneratorCollectSelection(API_URL.MANIFEST.GENERATOR_COLLECT_LIST).then((data) => {
        this.generatorCollectList = data;
        this.selectGeneratorCollectList = data;
        if (this.searchParam.collectCompanyId) {
          this.selectedGeneratorCollect = this.generatorCollectList.find(e => e.id === this.searchParam.collectCompanyId)
        }
      }).catch(() => {})
    },

    // 廃棄物種類選択肢取得API    API_URL.DOMAIN.JWNET_ITEM_TYPE
    getJwnetItemsApi() {
      axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE)
        .then((res) => {
          this.jwnetItemsOpt = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 処分事業者選択肢取得API
    getGeneratorDisposalApi() {
      this.getGeneratorDisposalSelection().then((data) => {
        this.generatorDisposalList = data
        this.selectGeneratorDisposalList = data;
        if (this.searchParam.disposalCompanyId) {
          this.selectedGeneratorDisposal = this.generatorDisposalList.find(e => e.id === this.searchParam.disposalCompanyId)
        }        
      }).catch(() => {})
    },

    // 運搬に紐づく処分事業者選択肢取得API
    getDisposalCompanySelectionForCollector() {
      this.getDisposalCompanySelectionForCollect().then((data) => {
        this.collectorDisposalList = data;
        this.selectCollectorDisposalList = data;
        if (this.searchParam.disposalCompanyId) {
          this.selectedGeneratorDisposal = this.collectorDisposalList.find(e => e.id === this.searchParam.disposalCompanyId)
        }
      }).catch(() => {});
    },

    // CBA品目選択肢取得API   MANIFEST.CBA_ITEMS
    getCbaItemsApi() {
      return axios
        .get(API_URL.MANIFEST.CBA_ITEMS)
        .then((res) => {
          this.cbaItems = res.data.cbaItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // マニフェスト件数取得Api
    getManifestCountApi() {
      axios
        .get(API_URL.MANIFEST.SUMMARY)
        .then((res) => {
          this.manifestStatusCount = res.data.manifestsSummaryList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // マニフェスト件数取得Api
    getCollectorManifestCountApi() {
      axios
        .get(API_URL.MANIFEST.SUMMARY_COLLECT + DOMAIN.MANIFEST_SEARCH_CONDITION.ALL)
        .then((res) => {
          this.manifestStatusCount = res.data.manifestsSummaryList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 処分事業場取得API
    getDisposalSiteApi() {
      this.getDisposalSiteSelection().then((data) => {
        this.disposalSiteList = data
        this.selectDisposalSiteList = data;
        if (this.searchParam.disposalSiteId) {
          this.selectedDisposalSite = this.disposalSiteList.find(e => e.id === this.searchParam.disposalSiteId)
        }
      }).catch(() => {})
    },

    // 排出の場合、排出事業者選択肢取得API
    getGeneratorCompanyApiForGenerate() {
      this.getGeneratorCompanySelection(API_URL.ENTRUST.GENERATE_SELECT_COMPANY).then((data) => {
        this.generatorCompanyList = data;
        this.selectGeneratorCompanyList = data;
        if (this.searchParam.generateCompanyId) {
          this.selectedGeneratorCompany = this.generatorCompanyList.find(e => e.id === this.searchParam.generateCompanyId)
        }
      }).catch(() => {})
    },

    // 運搬の場合、排出事業者選択肢取得API
    getGeneratorCompanyApiForCollect() {
      this.getGeneratorCompanySelection(API_URL.COLLECTOR.GENERATE_SELECT_COMPANY).then((data) => {
        this.generatorCompanyList = data;
        this.selectGeneratorCompanyList = data;
        if (this.searchParam.generateCompanyId) {
          this.selectedGeneratorCompany = this.generatorCompanyList.find(e => e.id === this.searchParam.generateCompanyId)
        }
      }).catch(() => {})

    },

    getDisposerManifestCountApi() {
        axios
        .get(API_URL.MANIFEST.SUMMARY_DISPOSAL)
        .then((res) => {
          this.manifestStatusCount = res.data.manifestsSummaryList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 処分事業者にひもづく排出事業者選択取得API
    getDisposalGenerateSelectcompanyApi() {
        this.getDisposalGenerateSelection().then((data) => {
            this.disposalGenerateList = data
            this.selectDisposalGenerateList = data
            if (this.searchParam.generateCompanyId) {
                this.selectedDisposalGenerate = this.disposalGenerateList.find(e => e.id === this.searchParam.generateCompanyId)
            }
        }).catch(() => {})
    },

    getDisposalCollectSelectcompanyApi() {
        this.getDisposalCollectSelection().then((data) => {
            this.disposalCollectList = data
            this.selectDisposalCollectList = data
            if (this.searchParam.collectCompanyId) {
                this.selectedDisposalCollect = this.disposalCollectList.find(e => e.id === this.searchParam.collectCompanyId)
            }
        }).catch(() => {})
    },

    getSelectoptionDisposalsiteApi() {
        axios
        .get(API_URL.MANIFEST.SELECTOPTION_DISPOSALSITE)
        .then((res) => {
            this.disposalSiteList = res.data.disposalSiteList
            this.selectDisposalSiteList = res.data.disposalSiteList
            if (this.searchParam.disposalSiteId) {
                this.selectedDisposalSite = this.disposalSiteList.find(e => e.id === this.searchParam.disposalSiteId)
            }
        }).catch((err) => {
          console.log(err);
        });
    },

    getSelectoptionGenerateStoreApi() {
        axios
        .get(API_URL.MANIFEST.SELECTOPTION_GENERATESTORE)
        .then((res) => {
          this.storeList = res.data.generateStoreList
          if (this.searchParam.generateCompanyId) {
            this.generateStoreList = this.storeList.filter(
              (st) => st.companyId == this.searchParam.generateCompanyId
            ).map(f => f.store)
            this.selectGenerateStoreList = this.storeList.filter(
              (st) => st.companyId == this.searchParam.generateCompanyId
            ).map(f => f.store)
          }
          while (this.selectedGenerateStore.length < this.searchParam.generateStoreIdList.length) {
            this.selectedGenerateStore.push(null);
          }
          this.searchParam.generateStoreIdList.forEach( (c, index) => {
            this.selectedGenerateStore[index] = c ? this.generateStoreList.find(e => e.id === c) : null
          })
          this.isLoadingStoreApi = false
        }).catch((err) => {
          this.isLoadingStoreApi = false
          console.log(err);
        })
    },

    // 排出のみ、排出事業者を選択しても排出事業場の絞り込みは行わない
    getSelectoptionGenerateStoreForGenerate() {
        axios
        .get(API_URL.MANIFEST.SELECTOPTION_GENERATESTORE)
        .then((res) => {
          this.storeList = res.data.generateStoreList
          this.generateStoreList = this.storeList.map(f => f.store)
          this.selectGenerateStoreList = this.storeList.map(f => f.store)
          while (this.selectedGenerateStore.length < this.searchParam.generateStoreIdList.length) {
            this.selectedGenerateStore.push(null);
          }
          this.searchParam.generateStoreIdList.forEach( (c, index) => {
            this.selectedGenerateStore[index] = c ? this.generateStoreList.find(e => e.id === c) : null
          })
          this.isLoadingSearchGenerateStore = false
        }).catch((err) => {
          console.log(err);
        })
    }
  },
};
