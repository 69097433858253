<template>
  <div>
    <div v-if="manifestList.length"  class="l-flex cols l-item" :class="{ disabled: isCallApiProcess }">
      <div class="u-txt-bold">選択中 {{ checkCount }} 件</div>
      <div class="l-flex cols" :class="{ disabled: !checkCount }">
        <div class="c-btn primary small" @click="clickPrintHandler">受渡確認票印刷</div>
      </div>
      <div class="c-btn secondary small upload" style="margin-left: auto;!important;width: 16rem;" @click="onClickCsvUploadModalDisplayButton">CSVアップロード</div>
      <div class="c-btn secondary small download" style="width: 16rem;" @click="clickCSVSearchHandler">CSVダウンロード</div>
    </div>

    <div class="c-infoList__wrap">
      <div class="c-infoList scroll l-box">
        <div class="c-infoList__row head">
          <div class="c-infoList__cell cb">
            <div class="c-checkbox">
              <input
                id="check-all-manifests"
                type="checkbox"
                v-model="isTableAllCheck"
                @change="changeAllCheckHandler"
              />
              <label class="c-checkbox__label" for="check-all-manifests">
                <span class="c-checkbox__box"></span>
              </label>
            </div>
          </div>
          <div class="c-infoList__cell md-s">マニフェスト番号</div>
          <div class="c-infoList__cell sm">ステータス</div>
          <div class="c-infoList__cell sm-m" @click="clickSortChangeHandler">
            <span
              class="c-infoList__cell__sort"
              :class="{
                asc: sortParams.type.toLowerCase() === 'asc',
                desc: sortParams.type.toLowerCase() === 'desc',
              }"
              >引き渡し日</span
            >
          </div>
          <div class="c-infoList__cell sm">交付日</div>
          <div class="c-infoList__cell sm">廃棄物名称</div>
          <div class="c-infoList__cell sm">詳細名称</div>
          <div class="c-infoList__cell sm">数量</div>
          <div class="c-infoList__cell sm">排出業者</div>
          <div class="c-infoList__cell sm">排出事業場</div>
          <div class="c-infoList__cell md">運搬先</div>
        </div>

        <!-- 検索結果あり -->
        <template v-if="manifestList.length">
          <div
            v-for="(manifest, index) of manifestList"
            :key="'manifest-' + index"
            :to="`/generator/contract/${manifest.id}`"
            class="c-infoList__row list"
          >
            <div class="c-infoList__cell cb text-dotted">
              <div class="c-checkbox">
                <input
                  :id="'checkbox-' + index"
                  type="checkbox"
                  v-model="manifest.isChecked"
                  @click="changeManifestCbHandler(index, manifest)"
                />
                <label class="c-checkbox__label" :for="'checkbox-' + index">
                  <span class="c-checkbox__box"></span>
                </label>
              </div>
            </div>
            <div @click="toDetail(manifest.manifestId)" class="c-infoList__cell md-s c-tooltip">
              <router-link :to="`/manifest/${manifest.manifestId}`">
                {{ manifest.manifestNo | blankText }}
                {{ manifest.manifestRegistType == manifestRegistType.COLLECTOR_REGIST ?  "（運代）" : "" }}
                {{ manifest.manifestRegistType == manifestRegistType.DISPOSER_REGIST ? "（処代）" : "" }}
              </router-link>
              <div v-if="manifest.collectReportLimit === limitStatus.WARNING"
                   class="c-status info-yellow">
                <div class="c-infoList__popup">
                  <span>運搬終了報告期限が迫っています。</span>
                </div>
              </div>
              <div v-if="manifest.collectReportLimit === limitStatus.DANGER"
                   class="c-status info-red">
                <div class="c-infoList__popup">
                  <span>運搬終了報告期限が過ぎています。</span>
                </div>
              </div>
            </div>
            <!-- ステータス -->
            <div @click="toDetail(manifest.manifestId)" class="c-infoList__cell sm text-dotted">
              {{ manifest.manifestStatusType == null ? "": manifest.manifestStatusType.name }}
            </div>

            <!-- 引き渡し日 -->
            <div class="c-infoList__cell sm-m full-date c-tooltip" @click="toDetail(manifest.manifestId)">
              <div class="c-infoText">
                <div>
                  {{ manifest.collectionDate | dateJpYMDddFormat }}
                </div>
              </div>
              <div v-if="manifest.collectionDateLimit" class="c-status" :class="manifest.collectionDateLimit">
                <div class="c-infoList__popup">
                  <span v-if="manifest.collectionDateLimit === limitStatus.INFO">交付時は入力が必要です<br></span>
                  <span v-if="manifest.collectionDateLimit === limitStatus.WARNING">交付期限が迫っています<br></span>
                  <span v-if="manifest.collectionDateLimit === limitStatus.DANGER">交付期限を超過しています<br></span>
                </div>
              </div>
            </div>

            <div class="c-infoList__cell sm" @click="toDetail(manifest.manifestId)">
              {{ manifest.issueDate | dateJpMDddFormat }}
            </div>
            <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
              {{ manifest.wasteName === null ? "" : manifest.wasteName.name}}
            </div>
            <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
              {{ manifest.detailWasteName === null ? "" : manifest.detailWasteName }}
            </div>
            <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
              {{ (manifest.wasteQuantity === null ? "" : manifest.wasteQuantity) + (manifest.wasteUnitType === null ? "" : manifest.wasteUnitType.name) }}
            </div>
            <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
              {{ manifest.generateCompany === null ? "" : manifest.generateCompany.name }}
            </div>
            <div class="c-infoList__cell sm text-dotted" @click="toDetail(manifest.manifestId)">
              {{ manifest.generateStore === null ? "" : manifest.generateStore.name }}
            </div>
            <div class="c-infoList__cell md text-dotted" @click="toDetail(manifest.manifestId)">
              {{ manifest.destinationCompanyName === null ? "" : manifest.destinationCompanyName }}
            </div>
            <!-- 終了報告修正ボタンを押して終了報告修正画面に遷移 -->
            <div v-if="manifest.isCollectReportFixable" class="c-infoList__cell sm sticky" @click="toCollectionReportEdit(manifest.manifestId)">
              <div>
                  <div class="c-btn secondary small">終了報告修正</div>
              </div>
            </div>
            <!-- 終了報告ボタンを押して終了報告画面に遷移 -->
            <div v-else-if="manifest.isCollectReportable" class="c-infoList__cell sm sticky" @click="toCollectionReport(manifest.manifestId)">
              <div>
                  <div class="c-btn secondary sm">終了報告</div>
              </div>
            </div>
          </div>
        </template>

        <!-- 検索結果なし -->
        <template v-else>
          <div class="c-infoList__row list">
            <div class="c-infoList__cell">
              検索条件に一致するマニフェストはありません。
            </div>
          </div>
        </template>
      </div>
    </div>

    <ManifestCsvUploadModal v-if="isDisplayCsvUploadModal" 
      v-bind:onClickCloseButton="hideCsvUploadModal"
      v-bind:onClickUploadButton="onClickUploadCsv"
      v-bind:onClickUploadRegisterCsvButton="onClickUploadRegisterCsv"
    />

  </div>
  <!-- table -->
</template>

<script>
import {DATE_LIMIT_STATUS} from "@/const/dateLimit";
import { DOMAIN } from "@/const";
import manifestCallApi from "../../mixins/callApi";
import ManifestCsvUploadModal from "../../components/ManifestCsvUploadModal";
import {ALERT_TYPE} from "@/const/dateLimit";

export default {
  components: {ManifestCsvUploadModal},
  mixins: [manifestCallApi],
  props: {
    manifestList: {
      type: Array,
    },
    sortParams: {
      type: Object,
    },
    checkCount: {
      type: Number,
    },
    isAllChecked: {
      type: Boolean,
    },
    isCallApiProcess: {
      type: Boolean,
    },
    paginationParams: {
      type: Object,
    }
  },

  data() {
    return {
      isTableAllCheck: false,
      limitStatus: DATE_LIMIT_STATUS,
      manifestRegistType: DOMAIN.MANIFEST_REGIST_TYPE,
      isDisplayCsvUploadModal: false,
      collectCompanyId: this.$store.getters["userInfo/get"].groupInfo.id,
    };
  },

  methods: {
    // 終了報告画面に遷移する
    toCollectionReport(manifestId) {
      this.setManifestAllPage()
      this.$router.push(`/manifest/collect/report/${manifestId}`);
    },

    // 終了報告修正画面に遷移する
    toCollectionReportEdit(manifestId) {
      this.setManifestAllPage()
      this.$router.push(`/manifest/collect/report/edit/${manifestId}`);
    },

    // CSVダウンロード押下
    clickCSVSearchHandler() {
      this.$emit("clickCSVDownloadForCollector");
    },

    // 詳細画面に遷移する
    toDetail(manifestId) {
      this.setManifestAllPage()
      const transitionSource = location.pathname;
      this.$router.push(`/manifest/${manifestId}?transitionSource=${transitionSource}`);
    },

    // 廃棄物名区分を表示する: 2次フェース向け_廃棄物種類情報待ち
    getJwnetItemManifestType(jwnetItemType, manifestType) {
      let join = (jwnetItemType !== null && manifestType !== null) ? "/" : "";
      let jwnetItemTypeName = (jwnetItemType === null) ? "": jwnetItemType.name;
      if(manifestType === null) return null;
      let manifestTypeName;
      if (manifestType === null) {
        manifestTypeName = "";
      } else if (manifestType.id === DOMAIN.MANIFEST_TYPE.DIGITAL)
      if(manifestType.id === DOMAIN.MANIFEST_TYPE.DIGITAL) {
        manifestTypeName = "電子"
      } else {
        manifestTypeName = "紙"
      }
      return jwnetItemTypeName + join + manifestTypeName
    },

    // マニフェスト全件チェックボックス
    changeAllCheckHandler() {
      if (this.isTableAllCheck) {
        this.$emit("clickAllCheck", true);
      } else {
        this.$emit("clickAllCheck", false);
      }
    },

    // 各マニフェストのチェックボックス
    changeManifestCbHandler(selectedIndex, manifest) {
      this.$emit("clickEachCb", selectedIndex, manifest);
    },

    // 引き渡し日ソート変更
    clickSortChangeHandler() {
      const newSortType = this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";
      this.$emit("clickSortChange", newSortType);
    },

    // 受渡確認票印刷押下
    clickPrintHandler() {
      this.$emit("clickPrint");
    },
    hideCsvUploadModal() {
      this.isDisplayCsvUploadModal = false;
    },
    onClickCsvUploadModalDisplayButton() {
      this.isDisplayCsvUploadModal = true;
    },

    onClickUploadCsv(csvFile) {
      return this.postManifestCsvupload(csvFile)
        .then(this.onUpload);
    },
    onClickUploadRegisterCsv(csvFile) {
      return this.postManifestCsvregister(csvFile)
        .then(this.onUpload);
    },

    onUpload() {
      this.hideCsvUploadModal();
      this.$emit('onUploadCsv');
    },
    setManifestAllPage() {
      this.$store.commit("searchParamManifestsAll/setManifestAllPage", this.paginationParams.page);
    },
  },

  watch: {
    isAllChecked() {
      this.isTableAllCheck = this.isAllChecked;
    },

    manifestList() {
      // 運搬報告期限アラート処理: 第一区間目の運搬報告のみ
      this.manifestList.forEach(manifest => {
        manifest.collectReportLimit = DATE_LIMIT_STATUS.NOT_YET

        if (manifest.collectCompany && manifest.collectCompany.length > 0) {
          if (manifest.collectCompany.filter(cc => cc.collectReportAlertTypeId === ALERT_TYPE.ATTENTION && cc.section === 1).length > 0) {
            manifest.collectReportLimit = DATE_LIMIT_STATUS.WARNING
          }
          if (manifest.collectCompany.filter(cc => cc.collectReportAlertTypeId === ALERT_TYPE.WARNING && cc.section === 1).length > 0) {
            manifest.collectReportLimit = DATE_LIMIT_STATUS.DANGER
          }
        }
      });
    }
  },
};
</script>
